import React, { Component } from 'react';
import axios from 'axios';
import Button from '../Button';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      newPasswordVisible: false,
      confirmPasswordVisible: false,
      // passwordMatch: true,
      Message: '',
    };
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTogglePasswordVisibility = (field) => {
    this.setState((prevState) => ({ [`${field}Visible`]: !prevState[`${field}Visible`] }));
  };

  handleChangePassword = () => {
    const { newPassword, confirmPassword } = this.state;

    if (newPassword === confirmPassword) {
        
        this.setState({ Message: '' });

        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);
        const userId = params.get('userId');
        const token = params.get('token');
        const request_body = {password: newPassword}

        const headers = {
            Authorization: `Bearer ${token}`,
          };

          axios.post(process.env.REACT_APP_SERVER_URL + `/user/resetPassword/${userId}`, request_body, { headers })
        .then((response) => {
            
            if (response.data) {
                this.setState({ Message: response.data });
            }

        })
        .catch((error) => {
            if(error.response.status == 403){
                this.setState({ Message: 'The link has expired. Please reset your password again from the login page.' });
            }
            else if(error.response.status == 400){
                this.setState({ Message: error.response.data.fieldErrors.password });
            }
            
            console.error('Error ', error);

        });

    } 
    else {
      this.setState({ Message: 'Passwords do not match. Please try again.' });
    }
  };

  render() {
    const { newPasswordVisible, confirmPasswordVisible, Message } = this.state;

    return (
      <div className="mjwi-setting-page">
        <div className="mjwi-reset-pwd">
        <h3>Reset Password</h3>
        <div>
          <label>New Password:</label>
          <div className="mjwi-reset-new-pwd">
          <input
            type={newPasswordVisible ? 'text' : 'password'}
            className="mjwi-new-password"
            name="newPassword"
            onChange={this.handleInputChange}
          />
          <i className={this.state.newPasswordVisible ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'} onClick={() => this.handleTogglePasswordVisibility('newPassword')}></i>
        </div>
        </div>
        <div>
          <label>Confirm Password:</label>
          <div className="mjwi-reset-new-pwd">
          <input
            type={confirmPasswordVisible ? 'text' : 'password'}
            className="mjwi-confirm-password"
            name="confirmPassword"
            onChange={this.handleInputChange}
          />
          <i className={this.state.confirmPasswordVisible ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'} onClick={() => this.handleTogglePasswordVisibility('confirmPassword')}></i>
        </div>
        </div>
        {Message && (
          <p className="mjwi-error">
            {Message}
          </p>
        )}

        <Button name="Change Password" eventHandler={() => { this.handleChangePassword() }} />
      </div>
      </div>
    );
  }
}

export default ResetPassword;
