import React, { Component } from 'react';
import apiInstance from '../apiInstance';
import getFromUrl from '../Functions/getFromUrl';
import Button from '../Button';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verified: false
        };
    }

    componentDidMount() {

        const userId = getFromUrl('uid');

        apiInstance.get(`/user/verifyEmail/${userId}`)
            .then(() => {
                this.setState(() => {
                    return ({
                        verified: true
                    })
                })

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    
    }
  
  render() {
    const { verified } = this.state;

    return (
      <div className="mjwi-setting-page">
        <div class="mjwi-profile-setting"><div>
        <h3>Personal Information</h3>
        {verified && (
          <p className="mjwi-success-message">
            Thank You for verifying your email address.
          </p>
        )}
        <Button name="Get Started" href="/home" />
        </div></div>
      </div>
    );
  }
}

export default ResetPassword;
