import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";

class Toolbox extends Component {

    constructor(props) {
        super(props);
    }

    setColorsAndPreview = async (type, color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('toolbox', type, color);
    }
    
    setToolboxTextColor = async (color) => {
        this.setColorsAndPreview('toolboxTextColor', color);
    }
    setToolboxBg = async (color) => {
        this.setColorsAndPreview('toolboxBg', color);
    }

    setGroupTextColor = async (color) => {
        this.setColorsAndPreview('groupTextColor', color);
    }
    setGroupBg = async (color) => {
        this.setColorsAndPreview('groupBg', color);
    }

    setGroupHoverColor = async (color) => {
        this.setColorsAndPreview('groupHoverColor', color);
    }

    setGroupHoverBg = async (color) => {
        this.setColorsAndPreview('groupHoverBg', color);
    }

    render(){

        const { widget, handleInputChange, toggleColorPicker } = this.props;
        const aw = widget.htmlData.accessibility;

        return (
            <div className="mjwi-acc-panel-wrapper">

                <div className='mjwi-row'>
                    <div className='mjwi-col33'>
                        <label>Title</label>
                    </div>
                    <div className='mjwi-col66'>
                        <input type="text" name="toolboxTitle" value={aw.toolbox.toolboxTitle} onChange={handleInputChange} />
                    </div>
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col50'>
                        <label>Text Color</label>
                    </div>
                    <div className='mjwi-col50 mjwi-clr-picker'>
                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: aw.toolbox.toolboxTextColor }}></span>
                        <HexAlphaColorPicker color={aw.toolbox.toolboxTextColor} onChange={this.setToolboxTextColor} style={{display: "none"}} className="mjwi-appfolio-color-picker" />
                        <HexColorInput color={aw.toolbox.toolboxTextColor} onChange={this.setToolboxTextColor} />
                    </div>
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col50'>
                        <label>Background</label>
                    </div>
                    <div className='mjwi-col50 mjwi-clr-picker'>
                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: aw.toolbox.toolboxBg }}></span>
                        <HexAlphaColorPicker color={aw.toolbox.toolboxBg} onChange={this.setToolboxBg} style={{display: "none"}} className="mjwi-appfolio-color-picker" />
                        <HexColorInput color={aw.toolbox.toolboxBg} onChange={this.setToolboxBg} />
                    </div>
                </div>

                <Accordion allowZeroExpanded={true}>
                    
                    <AccordionItem className='mjwi-sub-accordion'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Group Colors
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            <div className="mjwi-acc-panel-wrapper">
                                
                                <div className='mjwi-row'>
                                    <div className='mjwi-col50'>
                                        <label>Text Color</label>
                                    </div>
                                    <div className='mjwi-col50 mjwi-clr-picker'>
                                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: aw.toolbox.groupTextColor }}></span>
                                        <HexAlphaColorPicker color={aw.toolbox.groupTextColor} onChange={this.setGroupTextColor} style={{display: "none"}} className="mjwi-appfolio-color-picker" />
                                        <HexColorInput color={aw.toolbox.groupTextColor} onChange={this.setGroupTextColor} />
                                    </div>
                                </div>

                                <div className='mjwi-row'>
                                    <div className='mjwi-col50'>
                                        <label>Background</label>
                                    </div>
                                    <div className='mjwi-col50 mjwi-clr-picker'>
                                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: aw.toolbox.groupBg }}></span>
                                        <HexAlphaColorPicker color={aw.toolbox.groupBg} onChange={this.setGroupBg} style={{display: "none"}} className="mjwi-appfolio-color-picker" />
                                        <HexColorInput color={aw.toolbox.groupBg} onChange={this.setGroupBg} />
                                    </div>
                                </div>

                                <div className='mjwi-row'>
                                    <div className='mjwi-col50'>
                                        <label>Hover Text</label>
                                    </div>
                                    <div className='mjwi-col50 mjwi-clr-picker'>
                                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: aw.toolbox.groupHoverColor }}></span>
                                        <HexAlphaColorPicker color={aw.toolbox.groupHoverColor} onChange={this.setGroupHoverColor} style={{display: "none"}} className="mjwi-appfolio-color-picker" />
                                        <HexColorInput color={aw.toolbox.groupHoverColor} onChange={this.setGroupHoverColor} />
                                    </div>
                                </div>

                                <div className='mjwi-row'>
                                    <div className='mjwi-col50'>
                                        <label>Hover Background</label>
                                    </div>
                                    <div className='mjwi-col50 mjwi-clr-picker'>
                                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: aw.toolbox.groupHoverBg }}></span>
                                        <HexAlphaColorPicker color={aw.toolbox.groupHoverBg} onChange={this.setGroupHoverBg} style={{display: "none"}} className="mjwi-appfolio-color-picker" />
                                        <HexColorInput color={aw.toolbox.groupHoverBg} onChange={this.setGroupHoverBg} />
                                    </div>
                                </div>

                            </div>
                        
                        </AccordionItemPanel>
                    </AccordionItem>

                </Accordion>



            </div>

        );
    }

}

export default Toolbox;
