import React, { Component } from 'react';
import getCheckboxField from '../fields/checkbox';
import { HexColorInput, HexAlphaColorPicker } from 'react-colorful';

class Trustpilot extends Component {
    constructor(props) {
        super(props);

        const { widget } = this.props;
        const { htmlData } = widget;

        this.state = {
            widget: {
                htmlData: {
                    trustpilot: {
                        url: decodeURIComponent(widget.htmlData.trustpilot ? widget.htmlData.trustpilot.url : '' ),
                        logoStatus: htmlData?.trustpilot?.logoStatus || 'on',
                        companyNameStatus: htmlData?.trustpilot?.companyNameStatus || 'on',
                        addReviewStatus: htmlData?.trustpilot?.addReviewStatus || 'on',
                        reviewCountStatus: htmlData?.trustpilot?.reviewCountStatus || 'on',
                        reviewFilter: htmlData?.trustpilot?.reviewFilter || 'all',
                        layout: htmlData?.trustpilot?.layout || 'list',
                        color: htmlData?.trustpilot?.color || '#000000',
                        bgcolor: htmlData?.trustpilot?.bgcolor || '#ffffff',
                    },
                },
                widgetType: widget.widgetType.id,
                widgetTypeName: widget.widgetType.name,
                widgetTypeScripts: widget.widgetType.scripts,
                title: widget.title ? widget.title : '',
                expires: widget.expires ? widget.expires : '',
                isUrlChanged: true,
            },
            previewHtml: '',
            fieldErrors: '',
        };

    }

    handleInputChange = async (e) => {
        
        const { type, name, value, checked } = e.target;

        if(name == 'url'){
            this.setState((prevState) => ({
                widget: {
                    ...prevState.widget,
                    isUrlChanged: true
                },
            }));
        }
        else{
            this.setState((prevState) => ({
                widget: {
                    ...prevState.widget,
                    isUrlChanged: false
                },
            }));
        }

        await this.setState((prevState) => ({
            widget: {
                ...prevState.widget,
                [name]: value,
                htmlData: {
                    ...prevState.widget.htmlData,
                    trustpilot: {
                        ...prevState.widget.htmlData.trustpilot,
                        [name]: (type == 'checkbox' ? (checked ? 'on' : 'off'): value),
                    },
                },
            },
        }));

        let tpURL = this.state.widget.htmlData.trustpilot.url;
        if(tpURL){
            const { previewWidget } = this.props;
            await previewWidget(this.state.widget);
        }

    };

    toggleColorPicker = (e) => {
        const picker = e.target.nextElementSibling;
        if (picker.style.display === "none") {
            picker.style.display = "flex";
        } 
        else {
            picker.style.display = "none";
        }
    }

    setColorsAndPreview = async (stateItem, value) => {
        
        this.setState((prevState) => ({
            widget: {
                ...prevState.widget,
                isUrlChanged: false,
                htmlData: {
                    ...prevState.widget.htmlData,
                    trustpilot: {
                        ...prevState.widget.htmlData.trustpilot,
                        [stateItem]: value
                    },
                },
            },
        }), async () => {
            
            let trustpilotURL = this.state.widget.htmlData.trustpilot.url;
            if (trustpilotURL) {
                const { previewWidget } = this.props;
                // color is being changed from picker and input field. state update is being updated with previous color value. So, let's prepare widget with latest value.
                const widgetTosend = this.state.widget;
                await previewWidget(widgetTosend); 
            }
        });
        
    }

    async componentDidMount() {

        let tpURL = this.state.widget.htmlData.trustpilot.url;
        if(tpURL){
            const { previewWidget } = this.props;
            await previewWidget(this.state.widget);
        }

    }

    render() {

        const { widget } = this.state;
        const { selectedEditor } = this.props;

        const { title } = widget;
        const { trustpilot } = widget.htmlData;

        return (
            <div className="mjwi-editor-main-inner">

                {selectedEditor === 'Builder' && (
                    <div className="mjwi-editor-builder">

                        <label htmlFor="title">Widget Name</label>
                        <input
                            type="text"
                            name="title"
                            value={title}
                            placeholder="Title"
                            onChange={this.handleInputChange}
                        />

                        <label htmlFor="url">Trustpilot Profile URL</label>
                            <input
                                type="text"
                                name="url"
                                value={trustpilot.url}
                                placeholder="https://www.trustpilot.com/review/itrustcapital.com"
                                onChange={this.handleInputChange}
                            />

                    </div>
                )}
                {selectedEditor === 'Layout' && (
                    <div className="mjwi-editor-layout">
                        
                        {/* <label htmlFor="position">Position</label>
                        <select
                            id="position"
                            name="position"
                            value={position}
                            onChange={this.handleInputChange}
                        >
                            <option value='left'>Left</option>
                            <option value='right'>Right</option>
                        </select> */}
                        
                        <div className='mjwi-row'>
                            <div className='mjwi-col66'>
                                <label>
                                    {getCheckboxField('logoStatus', trustpilot.logoStatus, this.handleInputChange)}
                                    Logo
                                </label>
                            </div>
                        </div>

                        <div className='mjwi-row'>
                            <div className='mjwi-col66'>
                                <label>
                                    {getCheckboxField('companyNameStatus', trustpilot.companyNameStatus, this.handleInputChange)}
                                    Name
                                </label>
                            </div>
                        </div>

                        <div className='mjwi-row'>
                            <div className='mjwi-col66'>
                                <label>
                                    {getCheckboxField('addReviewStatus', trustpilot.addReviewStatus, this.handleInputChange)}
                                    Add Review
                                </label>
                            </div>
                        </div>

                        <div className='mjwi-row'>
                            <div className='mjwi-col66'>
                                <label>
                                    {getCheckboxField('reviewCountStatus', trustpilot.reviewCountStatus, this.handleInputChange)}
                                    Reviews Count
                                </label>
                            </div>
                        </div>

                        <div className='mjwi-row'>    
                            <div className='mjwi-col33'>
                                    <label>
                                        Filter
                                    </label>                                    
                                </div>
                                <div className='mjwi-col66'>
                                    <select
                                        id="reviewFilter"
                                        name="reviewFilter"
                                        value={trustpilot.reviewFilter}
                                        onChange={this.handleInputChange}
                                    >
                                        <option value="all">All Reviews</option>
                                        <option value="5">5 stars</option>
                                        <option value="4">4 stars and up</option>
                                        <option value="3">3 stars and up</option>
                                        <option value="2">2 stars and up</option>
                                        <option value="1">1 star and up</option>
                                    </select>
                                </div>
                        </div>

                        <div className='mjwi-row'>    
                            <div className='mjwi-col33'>
                                    <label>
                                        Layout
                                    </label>                                    
                                </div>
                                <div className='mjwi-col66'>
                                    <select
                                        id="layout"
                                        name="layout"
                                        value={trustpilot.layout}
                                        onChange={this.handleInputChange}
                                    >
                                        <option value="list">List</option>
                                        <option value="masonry">Masonry</option>
                                        <option value="carousel">Carousel</option>
                                    </select>
                                </div>
                        </div>

                        <div className='mjwi-row'>
                            <div className='mjwi-col33'>
                                <label>Text Color</label>
                            </div>
                            <div className='mjwi-col66 mjwi-clr-picker'>
                                
                                <span className="clrPickerVal" onClick={this.toggleColorPicker} style={{ backgroundColor: trustpilot.color }}></span>
                                <HexAlphaColorPicker color={trustpilot.color} onChange={(color) => this.setColorsAndPreview('color', color)} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={trustpilot.color} onChange={(color) => this.setColorsAndPreview('color', color)} />
                            </div>
                        </div>

                        <div className='mjwi-row'>
                            <div className='mjwi-col33'>
                                <label>Background</label>
                            </div>
                            <div className='mjwi-col66 mjwi-clr-picker'>
                                
                                <span className="clrPickerVal" onClick={this.toggleColorPicker} style={{ backgroundColor: trustpilot.bgcolor }}></span>
                                <HexAlphaColorPicker color={trustpilot.bgcolor} onChange={(color) => this.setColorsAndPreview('bgcolor', color)} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={trustpilot.bgcolor} onChange={(color) => this.setColorsAndPreview('bgcolor', color)} />
                            </div>
                        </div>

                    </div>
                )}


            </div>

        );
    }
}

export default Trustpilot;
