import React, { Component } from 'react';
import './assets/css/style.css';
import './assets/css/fa.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import Catalog from './components/Catalog';

import WidgetEditor from './components/WidgetEditor';
import PreviewWrapper from './components/Preview/PreviewWrapper';

import Register from './components/Account/Register';
import ResetPassword from './components/Account/ResetPassword';
import Account from './components/Account/Account';
import VerifyEmail from './components/Account/VerifyEmail';

import apiInstance from './components/apiInstance';
import PrivateRoute from './components/PrivateRoute';

import Devadmin from './components/Devadmin/Devadmin';

import { changeOverlayStatus } from './state/actionCreators/index'
import { connect } from 'react-redux';
import {changeCurrentUser} from './state/actionCreators/index'
import {changeWidgetTypes} from './state/actionCreators/index'

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false,
            checkedForLogin: false,
            isAdmin: false
        };
        
    }

    checkLogin = async() => {
        if( localStorage.getItem('token') ){ 
            await apiInstance.get('/')
                .then((response) => {
                    this.props.changeCurrentUser(response.data.user)
                    this.props.changeWidgetTypes(response.data.widgetTypes)
                    
                    this.setState ({
                        isLoggedIn: response.data.isUserLoggedIn,
                        isAdmin: (response.data.user.isAdmin ? response.data.user.isAdmin : false)
                    });

                })
                .catch((error) => {
                    console.error('Error ', error);

                    this.setState ({
                        isLoggedIn: false
                    });

                });
        }

        this.setState ({
            checkedForLogin: true
        });
    }

    componentDidMount() {
        this.checkLogin();
    }

    closeDD(e){
        
        // hide profile dropdown on outside clicks
        if(!e.target.classList.contains('profile-dd')){
            let profileDDElem = document.getElementById('profile-dd-content');
            if(profileDDElem){
                profileDDElem.classList.add('mjwi-hidden');
            }
        }

        // hide color pickers on outside clicks
        if(
            !e.target.classList.contains('clrPickerVal') 
            && !e.target.classList.contains('react-colorful__interactive') 
            && !e.target.classList.contains('react-colorful__pointer')
        ){
            const allColorPicker = document.querySelectorAll('.react-colorful');
            for (let index = 0; index < allColorPicker.length; index++) {
                allColorPicker[index].style.display = "none";
            }
        }
        
    }

  render() {
    
    let isLoggedIn = this.state.isLoggedIn;
    let checkedForLogin = this.state.checkedForLogin;

    if(checkedForLogin)
    {
        const urlLastPart = window.location.href.slice(window.location.href.lastIndexOf("/preview") + 1, window.location.href.lastIndexOf("?"));

        let isPreview = false;
        if(urlLastPart === 'preview')  {
            isPreview = true;
        }

        return (
        <>
        
            <div className="App" onClick={this.closeDD}>
            <div className={(this.props.overlay == 'true') ? "mjwi-overlay" : "mjwi-hidden"}></div>

            <Router>
                <Routes>
                    <Route
                        path="/preview"
                        element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <PreviewWrapper />
                        </PrivateRoute>
                        }
                    />
                </Routes>
            </Router>

            <Router>
                {!isPreview ? <Navbar /> : '' }

                {isLoggedIn ?

                <Routes>
                    <Route path="/resetPassword" element={<Navigate to="/home" />} />
                    <Route exact path="/" element={<Navigate to="/home" />} />
                    <Route path="/register" element={<Navigate to="/home" />} />
                </Routes>

                :
                <Routes>
                    <Route path="/abc"  element={<Navigate to="/register" />} />
                    <Route path="/resetPassword" element={<ResetPassword />} />
                    <Route path="/account" element={<Navigate to="/register" />} />
                    <Route path="/" element={<Register/>} />
                    <Route path="/register" element={<Register />} />
                </Routes>
                }

                <Routes>
                    <Route
                        path="/home"
                        element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <Home />
                        </PrivateRoute>
                        }
                    />
                    <Route
                        path="/account"
                        element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <Account />
                        </PrivateRoute>
                        }
                    />
                    <Route path="/catalog" element={<Catalog />} />
                    <Route path="/editor" element={<WidgetEditor />} />

                    <Route path="/verify-email" element={<VerifyEmail />} />

                    {this.state.isAdmin ? 
                    <Route
                        path="/devadmin"
                        element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <Devadmin />
                        </PrivateRoute>
                        }
                    />
                    :
                    <Route path="/devadmin"  element={<Navigate to="/home" />} />
                    }
                    

                </Routes>

            </Router>
            
            {!isPreview ? <Footer /> : '' }
            
            </div>
        </>

        );
        
    }

  }

}

const mapStateToProps = (state) => {
  return (
      {
          overlay: state.overlay,
          user: state.currentUser,
          widgetTypes: state.widgetTypes,
      }
  )
}

const mapDispatchToProps = (dispatch) => {
  return (
      {
          changeOverlayStatus: (status) => {dispatch(changeOverlayStatus(status))},
          changeCurrentUser: (user) => {dispatch(changeCurrentUser(user))},
          changeWidgetTypes: (widgetTypes) => {dispatch(changeWidgetTypes(widgetTypes))},
      }
  )
}

export default connect(mapStateToProps,mapDispatchToProps )(App);
