import apiInstance from "../apiInstance";
import store from "../../state/store";

import widgetPreview from '../Preview/widgetPreview.js';

import accessibilityPreview from '../Preview/widgets/accessibilityPreview.js';
import appfolioPreview from '../Preview/widgets/appfolioPreview.js';
import trustPilotPreview from '../Preview/widgets/trustPilotPreview.js';

const handlePreviewHTML = async (widget) => {
    var data = {
        error: '',
        fieldErrors: '',
        serveData: {},
        previewHtml: ''
    }
    const { widgetTypes } = store.getState();

    let type = '';
    for(const item of widgetTypes) {
        if (item.id === widget.widgetType) {
            type = item.name;
            break; 
        }
    }

    // console.log(widget);

    const dataNeeded = {
        name: widget.widgetTypeName,
        title: widget.title,
        htmlData: widget.htmlData,
        script: widget.widgetTypeScripts,
        isUrlChanged: widget.isUrlChanged
    }

    if(widget.widgetTypeName == 'accessibility'){ 
        // iframe calls the preview Wrapper first
        // data.previewHtml = `<iframe src='${process.env.REACT_APP_CLIENT_URL}/preview?widget=${encodeURIComponent( JSON.stringify(dataNeeded) )}' class="mjwi-preview-iframe"></iframe>`;
        await accessibilityPreview(dataNeeded);
    }
    else if(widget.widgetTypeName == 'appfolio'){
        await appfolioPreview(dataNeeded);
    }
    else if(widget.widgetTypeName == 'trustpilot'){
        await trustPilotPreview(widget);
    }
    else{
        // Skip widgetPreview Wrapper and directly call the preview function
        widgetPreview(dataNeeded);
    }

    return data;

};

export default handlePreviewHTML;
