import React, { Component } from 'react';
import { changeOverlayStatus } from '../state/actionCreators/index'
import { connect } from 'react-redux';

class InstallPopup extends Component {
    constructor(props) {
      super(props);
      this.state = {
        widget: '',
        displayPopup: false,
      };
    }
  
    openPopup = (widget, widgetType) => {
      this.setState((prevState) => {
        return ({
          ...prevState,
          widget: widget,
          widgetType: widgetType,
          displayPopup: true
        })
      })
      this.props.changeOverlayStatus('true');
    }
  
    closePopup = () => {
      this.setState((prevState) => {
        return ({
          ...prevState,
        //   widget: '',
          displayPopup: false,
        })
      })
      this.props.changeOverlayStatus('false');
    }
  
    handleCopyText = () => {
        const textArea = document.querySelector('.mjwi-textarea');
        textArea.select();
        document.execCommand('copy');

        document.querySelector('.mjwi-copy-tooltip').style.display = 'block';  
        setTimeout(() => {
            document.querySelector('.mjwi-copy-tooltip').style.display = 'none';
        }, 3000);

    };
  
    render() {
  
      let { displayPopup } = this.state;

      let isApflGroup = false;
      let apflGroup = '';

      if(this.state.widgetType === 'appfolio' && this.state.widget.htmlData.appfolio.group){
        isApflGroup = true;
        apflGroup = this.state.widget.htmlData.appfolio.group;
      }

      return (
        <div className={'mjwi-popup mjwi-install-popup' + (displayPopup ? '' : ' mjwi-hidden')}>
          <div className="mjwi-popup-content">
            <span className="mjwi-popup-close" onClick={this.closePopup}>
            <i className="fa-solid fa-xmark"></i>
            </span>
            <h3>Add Widget to your site</h3>
            <p>Place the following code whereever you want to appear on your site (HTML, Editor, Theme, Template, etc)</p>
            
            {
            (isApflGroup && 
                <>
                    <p>Display Listings from the Group:</p>
                    <div className="mjwi-install-code">
                    <textarea
                        className="mjwi-textarea"
                        value={'<div class="mj-widget" id="' + this.state.widget.id + '" data-type="' + this.state.widgetType + '" data-group="' + apflGroup + '"></div>\n<script src="' + process.env.REACT_APP_CLIENT_URL + '/mj-widgets.js"></script>'}
                        readOnly
                    />
                    <span className="mjwi-copy-icon" onClick={this.handleCopyText}>
                        <span className="mjwi-copy-tooltip">Copied</span>
                        <i class="fa-solid fa-copy"></i>
                    </span>
                    </div>
                </>
            )
            }
            <div className="mjwi-install-code">
              <textarea
                className="mjwi-textarea"
                value={'<div class="mj-widget" id="' + this.state.widget.id + '" data-type="' + this.state.widgetType + '"></div>\n<script src="' + process.env.REACT_APP_CLIENT_URL + '/mj-widgets.js"></script>'}
                readOnly
              />
              <span className="mjwi-copy-icon" onClick={this.handleCopyText}>
                <span className="mjwi-copy-tooltip">Copied</span>
                <i class="fa-solid fa-copy"></i>
              </span>
            </div>
          </div>
        </div>
      );
    }
  }

//   export default InstallPopup;
  const mapStateToProps = (state) => {
    return (
        {
            overlay: state.overlay,
        }
    )
  }
  
  const mapDispatchToProps = (dispatch) => {
    return (
        {
            changeOverlayStatus: (status) => {dispatch(changeOverlayStatus(status))},
        }
    )
  }
  
  export default connect(mapStateToProps,mapDispatchToProps,null, {forwardRef: true} )(InstallPopup);
  