
import React from 'react'; // live

// import { StrictMode } from 'react'; // dev

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './state/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	
	// remove the StrictMode tag when going live - Becaue it checks bugs in development and run everything twice
	// <StrictMode>
		<Provider store = {store}>
			<App />
		</Provider>
	// </StrictMode>
  
  /*
  <React.StrictMode>
   <Provider store = {store}>
        <App />
    </Provider>,
  </React.StrictMode>
  */
);

