import React, { Component } from 'react';
import apiInstance from '../apiInstance';
import PopupModal from './popups/PopupModal';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';

class DeavadminWidgets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            widgets: [],
            popupModal: React.createRef(),
            error: '',
        };

    }

    componentDidMount() {
        apiInstance.get('/devadmin/widget')
        .then(response => {
            const widgets = response.data.widgets;
            this.setState((prevState) => {
                return {
                    ...prevState,
                    widgets: widgets
                }
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    deleteWidget = (widgetId) => {
        this.setState((prevState) => {
            const updatedWidgets = prevState.widgets.filter(widget => widget.id !== widgetId);
            
            return {
                ...prevState,
                widgets: updatedWidgets
            };
        });
    };
    

    render() {
        const widgets = this.state.widgets;

        return(
            <div className='mjwidev-container'>
               {
                    widgets.map((widget) => {
                        return (<div className='mjwidev-wrapper'>
                            
                            <div className='mjwidev-inner-container'>
                                <span className='mjwidev-hdng'>{widget.title}</span>
                                <div><span className='mjwidev-ttl'>Widget Type: </span> <span>{widget.widgetType.name}</span></div>
                                <div><span className='mjwidev-ttl'>Widget Category: </span> <span>{widget.widgetType.category}</span></div>

                                <Accordion allowZeroExpanded={true}>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Html Data
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>

                                        {<pre>{JSON.stringify(widget?.htmlData?.[widget?.widgetType?.name], null, 2)}</pre>}
                                        
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                                
                            </div>
                            <div className='mjwidev-act-btns'>
                                <i onClick={() => this.state.popupModal.current.openPopup("deleteWidget", {widgetId: widget.id})} class="fa-solid fa-trash"></i>
                            </div>
                        </div>)
                    })
               }

               <PopupModal
                    ref={this.state.popupModal}
                    deleteWidget={this.deleteWidget}
                />

            </div>
        )

    }



}
export default DeavadminWidgets;