import React, { Component } from 'react';
import apiInstance from '../apiInstance';

class DevadminCollectionData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            error: '',
        };

    }

    fetchDocuments = (currentCollection) => {

        

    }

    componentDidMount() {

        this.setState((prevState) => {
            return ({
                ...prevState,
                error: ""
            })
        })

    }

    render() {

        const {currentCollection, documents} = this.props;

        // console.log(documents);
        
        const docs = documents.map((item, i) => { 
            return <div key={i}>
                <pre>{JSON.stringify(item, null, 2)}</pre>
            </div> });

        return(
            <div>

                {docs}

            </div>
        )

    }



}
export default DevadminCollectionData;