import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="mjwi-footer">
                <div className="mjwi-footer-copyright">
                © 2024 DevSpecial - A <a href="https://mediajedi.com" target="_blank">Media Jedi</a> Product. All Rights Reserved. 
                </div>
                <div className="mjwi-footer-links">
                <a href="#">Terms and Privacy Policy</a>
                </div>
            </div>
        );
    }
}
export default Footer;
