import React, { Component } from 'react';

class EditorLeftbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEditor: null,
    };

  }

  render() {
    const { tabs, activeTab, handleLeftBarClick } = this.props;

    return (
      <div className="mjwi-editor-leftbar">
        {Array.isArray(tabs) && tabs.includes('Builder') && (
          <div onClick={() => handleLeftBarClick('Builder')} className={(activeTab == 'Builder') ? 'active' : ''}>
            <i className="fa-solid fa-sliders"></i> Config
          </div>
        )}
        {Array.isArray(tabs) && tabs.includes('Layout') && (
          <div onClick={() => handleLeftBarClick('Layout')} className={(activeTab == 'Layout') ? 'active' : ''}>
            <i className="fa-solid fa-brush"></i> Design
          </div>
        )}
        {Array.isArray(tabs) && tabs.includes('Setting') && (
          <div onClick={() => handleLeftBarClick('Setting')} className={(activeTab == 'Setting') ? 'active' : ''}>
            <i className="fa-solid fa-gear"></i> Settings
          </div>
        )}
      </div>
    );
  }
}

export default EditorLeftbar;