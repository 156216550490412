import React, { Component } from 'react';
import Button from '../Button';
import apiInstance from '../apiInstance';

import { connect } from 'react-redux';
import {changeCurrentUser} from '../../state/actionCreators/index'

class ProfileSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayName: '',
            email: '',
            emailVerified: false,
            newPassword: '',
            confirmPassword: '',
            newPasswordVisible: false,
            confirmPasswordVisible: false,
            passwordMatch: true,
            deleteAcntPopup: false,
            error: '',
            fieldErrors: {},
        };
    }

    componentDidMount() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                fieldErrors: {},
                error: ""
            })
        })
        apiInstance.get('/user/profile')
            .then(response => {
                const userData = response.data.user;
                this.setState({
                    displayName: userData.displayName,
                    email: userData.email,
                    emailVerified: userData.emailVerified
                });
                this.props.changeCurrentUser(userData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleEmailVerification = (e) => {
        const request_body = {
            email: this.state.email
        };
        apiInstance.post('/user/verifyEmailRequest', request_body)
            .then(response => {

                document.querySelector('.mjwi-email-tooltip').style.display = 'block';  
                // console.log(response)

            })
            .catch(error => {
                if (error.response.status == 400 && error.response.data.fieldErrors) {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            fieldErrors: error.response.data.fieldErrors
                        })
                    })
                }
                else {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            error: error.response.data
                        })
                    })
                }
            });
    }

    handleSave = () => {
        this.setState((prevState) => {
            return ({
                ...prevState,
                fieldErrors: {},
                error: ""
            })
        })
        const { displayName, email } = this.state;
        const userId = localStorage.getItem('userId');
        const request_body = {
            displayName,
            email
        };

        apiInstance.put('/user/' + userId, request_body)
            .then(response => {
                this.props.changeCurrentUser(response.data.user);
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        emailVerified: response.data.user.emailVerified
                    }
                })

                document.querySelector('#acntSaveMsg').style.display = 'block';
                setTimeout(() => {
                    document.querySelector('#acntSaveMsg').style.display = 'none';
                }, 2500);

            })
            .catch(error => {
                if (error.response.status == 400 && error.response.data.fieldErrors) {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            fieldErrors: error.response.data.fieldErrors
                        })
                    })
                }
                else {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            error: error.response.data
                        })
                    })
                }
            });
    };

    handleDeleteAccount = () => {
        this.setState((prevState) => {
            return ({
                ...prevState,
                deleteAcntPopup: true
            })
        })
    };

    deleteAccountNow = () => {

        this.setState((prevState) => {
            return ({
                ...prevState,
                fieldErrors: {},
                error: ""
            })
        })

        const userId = localStorage.getItem('userId');
        apiInstance.delete('/user/' + userId)
            .then(response => {
                //console.log(response)
                window.location.href = "/home"
            })
            .catch(error => {
                console.log(error)
                if (error.response.status == 400 && error.response.data.fieldErrors) {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            fieldErrors: error.response.data.fieldErrors
                        })
                    })
                }
                else {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            error: error.response.data
                        })
                    })
                }
            });

    }

    cancelDeleteAccount = () => {
        this.setState((prevState) => {
            return ({
                ...prevState,
                deleteAcntPopup: false
            })
        })
    }

    handleTogglePasswordVisibility = (field) => {
        this.setState((prevState) => ({ [`${field}Visible`]: !prevState[`${field}Visible`] }));
    };

    resetPassword = () => {
        this.setState((prevState) => {
            return ({
                ...prevState,
                fieldErrors: {},
                error: ""
            })
        })

        const { newPassword, confirmPassword } = this.state;

        if (newPassword === confirmPassword) {
            let userId = localStorage.getItem("userId")
            const request_body = { password: newPassword }

            apiInstance.post(`/user/resetPassword/${userId}`, request_body)
                .then((response) => {
                    if (response.data.error) {

                    } else {
                        
                        localStorage.removeItem('token');
                        localStorage.removeItem('userId');

                        sessionStorage.removeItem('userSwitched');
                        sessionStorage.removeItem('adminId');
                        sessionStorage.removeItem('adminToken');
                        window.location.reload()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    if (error.response) {
                        if (error.response.status == 400 && error.response.data.fieldErrors) {

                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    fieldErrors: error.response.data.fieldErrors
                                })
                            })
                        }
                        else {

                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    error: error.response.data
                                })
                            })
                        }
                    }
                });
            this.setState({ passwordMatch: true });
        } else {
            this.setState({ passwordMatch: false });
        }
    }

    render() {
        let { fieldErrors, error } = this.state;

        return (
            <div className="mjwi-setting-page">
                <div className="mjwi-profile-setting">
                    <div>
                        <h3>Personal Information</h3>
                        {fieldErrors.profileImage && <span className="mjwi-error">{fieldErrors.profileImage}</span>}
                        <label>Display Name:</label>
                        <input
                            type="text"
                            name="displayName"
                            value={this.state.displayName}
                            onChange={this.handleChange}
                        />
                        <br />
                        {fieldErrors.displayName && <span className="mjwi-error">{fieldErrors.displayName}</span>}
                        
                        <label>Email:</label>
                        <div className="mjwi-profile-email">
                            <span className="mjwi-email-tooltip">Email Sent</span>
                            {this.state.emailVerified ? (<i class="fa-solid fa-circle-check"></i>) : (<i title="Email not verified. Click to send email." onClick={this.handleEmailVerification} className="fa-solid fa-circle-exclamation"></i>)}
                            <input
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                        </div>
                        {fieldErrors.email && <span className="mjwi-error">{fieldErrors.email}</span>}<br />
                        
                        <p className="mjwi-acnt-success-msg" id="acntSaveMsg">Settings Saved!</p>

                        <Button name="Save" eventHandler={() => { this.handleSave() }} />
                    </div>
                </div>

                <div className="mjwi-reset-pwd">
                    <h3>Reset Password</h3>
                    <div>
                        <label>New Password:</label>
                        <div className="mjwi-reset-new-pwd">
                        <input
                            type={this.state.newPasswordVisible ? 'text' : 'password'}
                            name="newPassword"
                            value={this.state.newPassword}
                            onChange={this.handleChange}
                        />
                        <i className={this.state.newPasswordVisible ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'} onClick={() => this.handleTogglePasswordVisibility('newPassword')}></i>
                        </div>
                        {fieldErrors.password && <span className="mjwi-error">{fieldErrors.password}</span>}
                    </div>
                    <div>
                        <label>Confirm Password:</label>
                        <div className="mjwi-reset-new-pwd">
                        <input
                            type={this.state.confirmPasswordVisible ? 'text' : 'password'}
                            name="confirmPassword"
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                        />
                        <i className={this.state.confirmPasswordVisible ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'} onClick={() => this.handleTogglePasswordVisibility('confirmPassword')}></i>
                        </div>
                    {!this.state.passwordMatch && (<p className="mjwi-error">Passwords do not match. Please try again.</p>)}
                    </div>
                    <br />
                    <Button name="Reset" eventHandler={() => { this.resetPassword() }} />
                </div>
                <div className="mjwi-delete-account">
                    <h3>Delete Account</h3>
                    <Button name="Delete Account" eventHandler={() => { this.handleDeleteAccount() }} />
                </div>

                {this.state.deleteAcntPopup && (
                    <div className='mjwi-popup mjwi-dlt-acnt-pp'>
                        <div className='dlt-acnt-pp-inner'>
                            <h2>Are you sure?</h2>
                            <span className="mjwi-popup-close" onClick={this.cancelDeleteAccount}>
                                 <i className="fa-solid fa-xmark"></i>
                            </span>
                            <div className='mjwi-row deleteAcntBtns'>
                                <div className="mjwi-col50">
                                    <Button name="Delete" eventHandler={() => { this.deleteAccountNow() }} />
                                </div>
                                <div className="mjwi-col50">
                                    <Button name="Cancel" eventHandler={() => { this.cancelDeleteAccount() }} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                
                <span className="mjwi-error">{error}</span>
            </div>

        );
    }
}

// export default ProfileSettings;
const mapStateToProps = (state) => {
    return (
        {
            user: state.currentUser,
        }
    )
}
const mapDispatchToProps = (dispatch) => {
    return (
      {
        changeCurrentUser: (user) => { dispatch(changeCurrentUser(user)) },
      }
    )
  }
export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
