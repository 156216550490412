const getRadioField = (name, value, dbValue, onchange) => {

    let checkedAttr = false;
    if(dbValue == value){
        checkedAttr = true;
    }
    
    return <input type="radio" name={name} value={value} checked={checkedAttr} onChange={onchange} />;

}

export default getRadioField;
