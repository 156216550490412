import React, {Component} from 'react'
import { Link } from 'react-router-dom';
export default class Button extends Component {
    render() {
        let {name, eventHandler, href, download, styleClass} = this.props;
        if(href == undefined) {
            href = "javascript:void(0);";
        }
        if(download == '') {
            download = false
        }
        if(!styleClass) {
            styleClass = '';
        }

        return (
            <Link download={download} onClick= {eventHandler} to={href} className={`${styleClass} mjwi-btn`} id={'mjwi-btn-' + name.replaceAll(' ', '-')}>
                {name}
            </Link>
      )
    }
}