import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from '../assets/images/logo.png';
import axios from 'axios';
import {changeCurrentUser} from '../state/actionCreators/index'

class Navbar extends Component {
    constructor(props) {
        super(props);
    }

    toggleDropdown = () => {
        document.getElementById('profile-dd-content').classList.toggle('mjwi-hidden');
    };

    handleSignOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');

        sessionStorage.removeItem('userSwitched');
        sessionStorage.removeItem('adminId');
        sessionStorage.removeItem('adminToken');
        window.location.href = '/';
    }

    switchBackToAdmin = () => {

        const userSwitched = sessionStorage.getItem("userSwitched");
        if(userSwitched === "true") {
            const adminId = sessionStorage.getItem("adminId");
            const adminToken = sessionStorage.getItem("adminToken");

            const baseURL = process.env.REACT_APP_SERVER_URL;

            axios.get(`${baseURL}/devadmin/user/${adminId}`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`
                }
            })
            .then(response => {
                const user = response.data.user;

                localStorage.setItem('token', user.token);
                localStorage.setItem('userId', user.id);

                sessionStorage.removeItem('adminToken');
                sessionStorage.removeItem('adminId');
                sessionStorage.setItem('userSwitched', false);

                this.props.changeCurrentUser(user)
                window.location.href = "/devadmin";
                
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        }
    }

    render() {
        let isLoggedIn = false;
        const user = this.props.user;
        let userDisplayName = '';

        if (Object.keys(user).length != 0) {
            isLoggedIn = true;
            userDisplayName = user.displayName;
        }

        let userSwitched = isLoggedIn && sessionStorage.getItem("userSwitched");

        return (
            <div className="mjwi-navbar">
                <div className="mjwi-navbar-title">
                    <img src={logo} className="mjwi-logo" />
                </div>
                <div className="mjwi-navbar-links">
                    <div onClick={this.switchBackToAdmin} className={`mjwi-nav-link` + (userSwitched === 'true' ? '' : ' mjwi-hidden' )} style={{cursor:'pointer'}}>Switch back to admin</div>
                    <Link className="mjwi-nav-link" to="/home"> Home </Link>
                    {(isLoggedIn) ? <Link className="mjwi-nav-link" to="/catalog">
                        Catalog
                    </Link> : ''}
                    
                    {
                        (isLoggedIn)
                            ? (
                                <div className="mjwi-nav-link" onClick={this.toggleDropdown}>
                                     <div className="profile-dd"><i className="fa-regular fa-circle-user "></i>{userDisplayName}</div>

                                    <div id="profile-dd-content" className="mjwi-dropdown-content mjwi-hidden">
                                        
                                        <Link to="/account">
                                        <div className='mjwi-dropdown-item'>
                                        <i class="fa-solid fa-user-pen"></i> Edit Profile
                                        </div>
                                        </Link>
                                        <div className='mjwi-dropdown-item' onClick={this.handleSignOut}>
                                            <span> <i class="fa-solid fa-arrow-right-from-bracket"></i> Sign Out </span>
                                        </div>
                                    </div>

                                </div>
                            )
                            : (<></>)
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return (
        {
            user: state.currentUser,
        }
    )
}
const mapDispatchToProps = (dispatch) => {
    return (
      {
        changeCurrentUser: (user) => { dispatch(changeCurrentUser(user)) },
      }
    )
}
export default connect(mapStateToProps,mapDispatchToProps )(Navbar);
