import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import apiInstance from './apiInstance';
import { connect } from 'react-redux';

class Catalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widgets: [],
      searchQuery: '',
      error: '',
    };
  }

  componentDidMount() {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const widgetId = params.get('widgetId');
    if(widgetId){
      apiInstance.delete(`/widget/${widgetId}`)
      .then(response => {
        console.log(response)
        
      })
      .catch(error => {
          console.error('Error deleting the widget:', error);
      });
    }
   
    const urlWithoutParams = (window.location.href).split('?')[0];
    window.history.pushState({}, 'title', urlWithoutParams)
  }

  updateErrorMessage = (error = "", msg = "") => {
    this.setState((prevState) => {
        return ({
            ...prevState,
            error: error,
            msg: msg
        })
    })
}

  handleSearchChange = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  render() {
    const { searchQuery, error } = this.state;

    const widgets = (this.props.widgetTypes)
    const filteredWidgets = widgets.filter(widget =>
      widget.name.toLowerCase().includes(searchQuery.toLowerCase())
      ||
      (widget.desc ? widget.desc.toLowerCase().includes(searchQuery.toLowerCase()) : '')
    );

    return (
      <div className="mjwi-ct-container">
        <div className="mjwi-ct-content">
          <div className="mjwi-promo-banner">
            <h1>Premium Widget Library</h1>
            <h3>Browse through our extensive collection of powerful, fully customizable & mobile-friendly widgets.</h3>
          </div>
          <div className="mjwi-ct-search">
            <input
              type="text"
              id="searchInput"
              value={searchQuery}
              placeholder="What are you looking for?"
              onChange={this.handleSearchChange}
            />
          </div>
          <div className="mjwi-ct-list mjwi-row">
            {filteredWidgets.map(widget => (
              
              <Link to={`/editor?widgetType=${widget.id}`} key={widget.id} className="mjwi-ct-card">
                  <div className="mjwi-ct-card-banner">
                    <img src={"/images/" + widget.name + '-banner.png'} className="mjwi-catalog-banner" />
                </div>
                <div className="mjwi-ct-card-logoname">
                <img src={"/images/" + widget.name + '-logo.png'} className="mjwi-catalog-logo" />
                  <h3 className="mjwi-ct-card__title">
                  {widget.name}
                  </h3>
                  </div>
                  <div className="mjwi-ct-card-desc">
                  <p className="mjwi-ct-card__content">{widget.desc}</p>
                </div>
               </Link>
             ))}
          </div>
        </div>
        <div className={'mjwi-error' + (error ? '' : ' mjwi-hidden')}>{error}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return (
      {
          widgetTypes: state.widgetTypes,
      }
  )
}

export default connect(mapStateToProps,null )(Catalog);