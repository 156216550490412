import React, { Component } from 'react';
import apiInstance from '../apiInstance';
import PopupModal from './popups/PopupModal';

class DeavadminAvailableWidgets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            availableWidgets: [],
            popupModal: React.createRef(),
            error: '',
        };

    }

    componentDidMount() {
        apiInstance.get('/devadmin/availableWidget')
        .then(response => {
            const availableWidgets = response.data.availableWidgets;
            this.setState((prevState) => {
                return {
                    ...prevState,
                    availableWidgets: availableWidgets
                }
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    addAvailableWidget = (widget) => {

        this.setState((prevState) => {

            const updatedAvailableWidgets = [
                ...this.state.availableWidgets,
                widget
            ]

            return {
                ...prevState,
                availableWidgets: updatedAvailableWidgets
            }
        })
    }

    updateAvailableWidget = (updatedWidget) => {
        this.setState((prevState) => {
            const updatedAvailableWidgets = prevState.availableWidgets.map(widget => 
                widget.id === updatedWidget.id ? updatedWidget : widget
            );
            
            return {
                ...prevState,
                availableWidgets: updatedAvailableWidgets
            };
        });
    };

    deleteAvailableWidget = (widgetId) => {
        this.setState((prevState) => {
            const updatedAvailableWidgets = prevState.availableWidgets.filter(widget => widget.id !== widgetId);
            
            return {
                ...prevState,
                availableWidgets: updatedAvailableWidgets
            };
        });
    };
    

    render() {
        const availableWidgets = this.state.availableWidgets;

        return(
            <div className='mjwidev-container'>
                <div className='mjwidev-add-avl-widget-btn mjwi-btn' onClick={() => this.state.popupModal.current.openPopup("addAvailableWidget")}>Add Widget</div>
               {
                    availableWidgets.map((widget) => {
                        return (<div className='mjwidev-wrapper'>
                            
                            <div className='mjwidev-inner-container'>
                                <span className='mjwidev-hdng'>{widget.name}</span>
                                <span>{widget.desc}</span>
                                <div><span className='mjwidev-ttl'>Price: </span> <span>${widget.price}</span></div>
                                <div><span className='mjwidev-ttl'>Category: </span> <span>{widget.category}</span></div>
                                <div><span className='mjwidev-ttl'>Stripe Price ID: </span> <span>{widget.stripePriceId}</span></div>
                                <div><span className='mjwidev-ttl'>Stripe Product ID: </span> <span>{widget.stripeProductId}</span></div>
                                <div><span className='mjwidev-ttl'>Main script: </span> <span>{widget.scripts.main}</span></div>
                                <div><span className='mjwidev-ttl'>Lib script: </span> <span>{widget.scripts.lib}</span></div>
                            </div>
                            <div className='mjwidev-act-btns'>
                                <i onClick={() => this.state.popupModal.current.openPopup("updateAvailableWidget", {widget: widget})} class="fa-solid fa-pen-to-square"></i>
                                <i onClick={() => this.state.popupModal.current.openPopup("deleteAvailableWidget", {availableWidgetId: widget.id})} class="fa-solid fa-trash"></i>
                            </div>
                        </div>)
                    })
               }

               <PopupModal
                    ref={this.state.popupModal}
                    addAvailableWidget={this.addAvailableWidget}
                    updateAvailableWidget={this.updateAvailableWidget}
                    deleteAvailableWidget={this.deleteAvailableWidget}
                />

            </div>
        )

    }



}
export default DeavadminAvailableWidgets;