import axios from 'axios';

const appfolioPreview = async (widget) => {

    // console.log(widget);

    // don't load css if already there -- PENDING -> NEED TO REMOVE THIS CSS FILE WHEN NOT IN APPFOLIO EDIT/PREVIEW SCREEN
    const apflExistingStyle = document.getElementById('mjwi-style-appfolio');
    if (!apflExistingStyle) {
        // existingStyle.parentNode.removeChild(existingStyle);
        
        var commonStyleAppfolio = process.env.REACT_APP_CLIENT_URL + '/styles/widget/appfolio.css';
        var styleElementAppfolio = document.createElement('link');
        styleElementAppfolio.href = commonStyleAppfolio;
        styleElementAppfolio.id = 'mjwi-style-appfolio';
        styleElementAppfolio.rel = "stylesheet";
        styleElementAppfolio.type = "text/css";
        document.head.appendChild(styleElementAppfolio);
        
    }

    const htmldata = widget.htmlData.appfolio;
    const heading = htmldata.header.appfolioHeading;
    const displayFilters = htmldata.appfolioDisplayFilters;
    const clientGmapApi = htmldata.clientGmapApi || '';

    window.apflHtmlData = htmldata;
    window.mjServerUrl = process.env.REACT_APP_SERVER_URL 
    window.mjFrontend = process.env.REACT_APP_CLIENT_URL;

    // Load css variables
    var cssRoot = document.querySelector(':root');

    // Common for both url changed and not changed
    cssRoot.style.setProperty('--listing-item-rent', (htmldata.layout.layoutRent === 'on' ? 'inline' : 'none'));
    cssRoot.style.setProperty('--listing-item-date', (htmldata.layout.layoutDate === 'on' ? 'inline' : 'none'));
    cssRoot.style.setProperty('--listing-item-title', (htmldata.layout.layoutTitle === 'on' ? 'inline' : 'none'));
    cssRoot.style.setProperty('--listing-item-addr', (htmldata.layout.layoutAddress === 'on' ? 'inline' : 'none'));
    cssRoot.style.setProperty('--listing-item-beds', (htmldata.layout.layoutBeds === 'on' ? 'inline' : 'none'));
    cssRoot.style.setProperty('--listing-item-baths', (htmldata.layout.layoutBaths === 'on' ? 'inline' : 'none'));
    cssRoot.style.setProperty('--listing-item-detail', (htmldata.layout.layoutDetail === 'on' ? 'inline' : 'none'));
    cssRoot.style.setProperty('--listing-item-apply', (htmldata.layout.layoutApply === 'on' ? 'inline' : 'none'));

    cssRoot.style.setProperty('--detail-section-address', (htmldata.detail.detailAddress === 'on' ? 'inline' : 'none'));
    
    
    // Data file for the widget -- Only fetch data again if the Appfolio URL has changed
    if(widget.isUrlChanged){

        // Load data script
        const existingScript = document.getElementById('mjwi-script-appfolio-data');
        if (existingScript) {
            existingScript.parentNode.removeChild(existingScript);
        }
        const scriptElement = document.createElement('script');
        scriptElement.src = process.env.REACT_APP_CLIENT_URL + '/scripts/widget/appfolio/appfolio-data.js';
        try {
            const response = await axios.head(scriptElement.src, { timeout: 5000 });
            if(response.status === 200){
                scriptElement.type = 'text/javascript';
                scriptElement.id = 'mjwi-script-appfolio-data';
                document.head.appendChild(scriptElement);
            }
        }
        catch{
            console.log('Data file does not exist');
        }

        // Generate html for first time or when URL changed
        let html = '';

        // preloader styling
        html = '<style>';
        html += '.mjwi_apfl_pre_skeleton{opacity:.7;animation:1s linear infinite alternate mjwi_apfl_pre_skeleton-loading}.mjwi_apfl_pre_skeleton-text{width:70%;height:20px}@keyframes mjwi_apfl_pre_skeleton-loading{0%{background-color:#a3b7c1}100%{background-color:#eff3f4}}.mjwi_apfl_pre_grid{display:flex;flex-wrap:wrap;width: 100%;}.mjwi_apfl_pre_card{box-shadow:rgba(0,0,0,.1) 0 0 6px 0,rgba(0,0,0,.06) 0 2px 4px -1px;border-radius:5px;margin:0 0.5%;margin-bottom:2rem;}.mjwi_apfl_pre_header{margin-bottom:0;display:flex;align-items:center}.mjwi_apfl_pre_header-img{width:100%;height:275px;object-fit:cover;margin:0;border:0}';
        html += '.mjwi_apfl_pre_text{background: rgba(89, 143, 205, 0.1); padding: 1em; gap: 10px; display: flex; flex-direction: column;}';
        html += '.mjwi_apfl_pre_skeleton-text:first-child{width:80%} .mjwi_apfl_pre_skeleton-text:last-child{width:60%} .mjwi_apfl_pre_skeleton-btn{height: 40px; margin-top: 5px; width: 50%}';
        html += '</style>';

        const headingStyle = 'style="color:' + htmldata.header.headercolor + '; font-family:' + htmldata.header.headerFontFamily + '; font-size:' + htmldata.header.headerFontSize + 'px; font-weight:' + (htmldata.header.headerFontBold === 'on' ? 'bold' : '') + '; font-style:' + (htmldata.header.headerFontItalic === 'on' ? 'Italic' : '') + ';"';

        html += '<div class="main-listings-page ' + htmldata.layout.layoutTemplate + '">';
        html += '    <div class="mjwi-apfl-details-popup" style="display: none"></div>';
        html += '    <div class="mjwi-listings-container">';
        html += '       <div class="listing-filters" style="background:' + htmldata.header.headerbg + '">';
        html += '           <div class="apfl_page_hdng" ' + headingStyle + '>' + heading + '</div>';

        html += '<input id="filterSearch" name="filters[listing_address]" type="search" placeholder="Search by address..." style="display:' + (displayFilters.search === 'on' ? "inline" : "none") + '">';
        html += '<select id="filterMinRent" name="filters[market_rent_from]" style="display:' + (displayFilters.minRent === 'on' ? "inline" : "none") + '"><option>Min Rent</option></select>';
        html += '<select id="filterMaxRent" name="filters[market_rent_to]" style="display:' + (displayFilters.maxRent === 'on' ? "inline" : "none") + '"><option>Max Rent</option></select>';
        html += '<select id="filterBeds" name="filters[bedrooms]" style="display:' + (displayFilters.beds === 'on' ? "inline" : "none") + '"><option>Beds</option></select>';
        html += '<select id="filterBaths" name="filters[bathrooms]" style="display:' + (displayFilters.baths === 'on' ? "inline" : "none") + '"><option>Baths</option></select>';
        html += '<select id="filterCities" name="filters[cities]" style="display:' + (displayFilters.cities === 'on' ? "inline" : "none") + '"><option>City</option></select>';
        html += '<select id="filterZip" name="filters[zips]" style="display:' + (displayFilters.zip === 'on' ? "inline" : "none") + '"><option>Zip</option></select>';
        html += '<select id="filterCats" name="filters[cats]" style="display:' + (displayFilters.cats === 'on' ? "inline" : "none") + '"><option>Cats allowed</option><option value="yes">Yes</option><option value="no">No</option></select>';
        html += '<select id="filterDogs" name="filters[dogs]" style="display:' + (displayFilters.dogs === 'on' ? "inline" : "none") + '"><option value="">Dogs allowed</option><option value="large">Large</option><option value="small">Small</option><option value="no">No</option></select>';
        html += '<select id="filterSort" name="filters[sort_order]" style="display:' + (displayFilters.sorting === 'on' ? "inline" : "none") + '"><option value="date_posted">Most Recent</option><option value="rent_asc">Rent (Low to High)</option><option value="rent_desc">Rent (High to Low)</option><option value="bedrooms_asc">Bedrooms (ASC)</option><option value="bedrooms_desc">Bedrooms (DESC)</option><option value="availability">Availability</option></select>';
        html += '<input id="filterMoveIn" name="filters[desired_move_in]" type="text" placeholder="Desired Move In Date" style="display:' + (displayFilters.desiredMoveIn === 'on' ? "inline" : "none") + '">';
        
        html += '       </div>'; // listing-filters

        html += '<div class="mjwi-apfl-listing-map-wrapper">';

        if (clientGmapApi) {
            html += '<div id="googlemap"></div>';
        }

        html += '       <div class="all-listings">';

        // Preloader
        // html += '		<div id="preloader_sec" class="mjwi_apfl_pre_grid">';
        for (var i=0; i<6; i++){
            html += '		<div class="mjwi_apfl_pre_card">';
            html += '			<div class="mjwi_apfl_pre_header">';
            html += '				<img class="mjwi_apfl_pre_header-img mjwi_apfl_pre_skeleton" />';
            html += '			</div>';
            html += '			<div class="mjwi_apfl_pre_text">';
            html += '				<div class="mjwi_apfl_pre_skeleton mjwi_apfl_pre_skeleton-text"></div>';
            html += '				<div class="mjwi_apfl_pre_skeleton mjwi_apfl_pre_skeleton-text"></div>';
            html += '				<div class="mjwi_apfl_pre_skeleton mjwi_apfl_pre_skeleton-text"></div>';
            html += '				<div style="display: flex; gap: 20px;"><div class="mjwi_apfl_pre_skeleton mjwi_apfl_pre_skeleton-btn"></div><div class="mjwi_apfl_pre_skeleton mjwi_apfl_pre_skeleton-btn"></div></div>';
            html += '			</div>';
            html += '		</div>';
        }
        // html += '		</div>';
        // -- Preloader --

        html += '       </div>'; // all-listings

        html += '     </div>'; // mjwi-apfl-listing-map-wrapper

        html += '   </div>'; // mjwi-listings-container

        html += '</div>'; // main-listings-page
        
        document.querySelector(".mj-appfolio").innerHTML = html;

        // return html;

    }
    else{
        
        // console.log(htmldata);

        // HTML is already here - just update it
        // Header
        document.querySelector(".apfl_page_hdng").innerHTML = heading;
        document.querySelector(".listing-filters").style.background = htmldata.header.headerbg;
        document.querySelector(".listing-filters .apfl_page_hdng").style.color = htmldata.header.headercolor;
        document.querySelector(".listing-filters .apfl_page_hdng").style.fontFamily = htmldata.header.headerFontFamily;
        document.querySelector(".listing-filters .apfl_page_hdng").style.fontSize = htmldata.header.headerFontSize + 'px';
        document.querySelector(".listing-filters .apfl_page_hdng").style.fontWeight = htmldata.header.headerFontBold === 'on' ? 'bold' : '';
        document.querySelector(".listing-filters .apfl_page_hdng").style.fontStyle = htmldata.header.headerFontItalic === 'on' ? 'italic' : '';
        
        // filters
        document.querySelector("#filterSearch").style.display = (displayFilters.search === 'on' ? 'inline' : 'none');
        document.querySelector("#filterMinRent").style.display = (displayFilters.minRent === 'on' ? 'inline' : 'none');
        document.querySelector("#filterMaxRent").style.display = (displayFilters.maxRent === 'on' ? 'inline' : 'none');
        document.querySelector("#filterBeds").style.display = (displayFilters.beds === 'on' ? 'inline' : 'none');
        document.querySelector("#filterBaths").style.display = (displayFilters.baths === 'on' ? 'inline' : 'none');
        document.querySelector("#filterCities").style.display = (displayFilters.cities === 'on' ? 'inline' : 'none');
        document.querySelector("#filterZip").style.display = (displayFilters.zip === 'on' ? 'inline' : 'none');
        document.querySelector("#filterCats").style.display = (displayFilters.cats === 'on' ? 'inline' : 'none');
        document.querySelector("#filterDogs").style.display = (displayFilters.dogs === 'on' ? 'inline' : 'none');
        document.querySelector("#filterSort").style.display = (displayFilters.sorting === 'on' ? 'inline' : 'none');
        document.querySelector("#filterMoveIn").style.display = (displayFilters.desiredMoveIn === 'on' ? 'inline' : 'none');

        // Template
        document.querySelector(".main-listings-page").classList.remove('eagle');
        document.querySelector(".main-listings-page").classList.remove('hawk');
        document.querySelector(".main-listings-page").classList.add(htmldata.layout.layoutTemplate);

        // Column Class
        document.querySelector(".all-listings").classList.remove('single-column');

        // Columns
        let itemWidth = '';
        const columnsCount = parseInt(htmldata.appfolioCol);
        if(columnsCount === 1){
            itemWidth = '99%'; // because of 1% margin (0.5 on each side)
            document.querySelector(".all-listings").classList.add('single-column');
        }
        if(columnsCount === 2){
            itemWidth = '49%';
        }
        if(columnsCount === 3){
            itemWidth = '32.33%';
        }
        if(columnsCount === 4){
            itemWidth = '24%';
        }
        cssRoot.style.setProperty('--listing-item-width', itemWidth);

        // item properties
        cssRoot.style.setProperty('--layout-font-family', htmldata.layout.layoutFontFamily);
        cssRoot.style.setProperty('--listing-item-section-bg', htmldata.layout.layoutSectionBg);
        cssRoot.style.setProperty('--listing-item-rent-color', htmldata.layout.layoutRentColor);
        cssRoot.style.setProperty('--listing-item-rent-bg', htmldata.layout.layoutRentBg);
        cssRoot.style.setProperty('--listing-item-date-color', htmldata.layout.layoutDateColor);
        cssRoot.style.setProperty('--listing-item-date-bg', htmldata.layout.layoutDateBg);
        cssRoot.style.setProperty('--listing-item-title-size', htmldata.layout.layoutTitleSize + 'px');
        cssRoot.style.setProperty('--listing-item-title-color', htmldata.layout.layoutTitleColor);
        cssRoot.style.setProperty('--listing-item-address-size', htmldata.layout.layoutAddressSize + 'px');
        cssRoot.style.setProperty('--listing-item-address-color', htmldata.layout.layoutAddressColor);
        cssRoot.style.setProperty('--listing-item-bed-color', htmldata.layout.layoutBedColor);
        cssRoot.style.setProperty('--listing-item-bed-icon-color', htmldata.layout.layoutBedIconColor);
        cssRoot.style.setProperty('--listing-item-bath-color', htmldata.layout.layoutBathColor);
        cssRoot.style.setProperty('--listing-item-bath-icon-color', htmldata.layout.layoutBathIconColor);
        
        cssRoot.style.setProperty('--detail-btn-color', htmldata.layout.layoutDetailColor);
        cssRoot.style.setProperty('--detail-btn-bg-color', htmldata.layout.layoutDetailBg);
        cssRoot.style.setProperty('--apply-btn-color', htmldata.layout.layoutApplyColor);
        cssRoot.style.setProperty('--apply-btn-bg-color', htmldata.layout.layoutApplyBg);

        // detail popup properties
        if(document.querySelector(".mjwi-apfl-details-popup .listing-sec")){
            document.querySelector(".mjwi-apfl-details-popup .listing-sec").classList.remove('half');
            document.querySelector(".mjwi-apfl-details-popup .listing-sec").classList.remove('full');
            document.querySelector(".mjwi-apfl-details-popup .listing-sec").classList.add(htmldata.detail.detailTemplate);
        }

        cssRoot.style.setProperty('--detail-section-bg-color', htmldata.detail.detailSectionBg);
        cssRoot.style.setProperty('--detail-section-text-color', htmldata.detail.detailSectionTextColor);
        cssRoot.style.setProperty('--detail-section-ff', htmldata.detail.detailSectionFF);

        cssRoot.style.setProperty('--detail-section-address', (htmldata.detail.detailAddress === 'on' ? 'block' : 'none' ));
        cssRoot.style.setProperty('--detail-section-address-color', htmldata.detail.detailAddressColor);
        cssRoot.style.setProperty('--detail-section-address-ff', htmldata.detail.detailAddressFontFamily);
        cssRoot.style.setProperty('--detail-section-address-fs', htmldata.detail.detailAddressFontSize + 'px');
        cssRoot.style.setProperty('--detail-section-address-fw', (htmldata.detail.detailAddressFontBold === 'on' ? 'bold' : 'normal'));

        cssRoot.style.setProperty('--detail-section-rent', (htmldata.detail.detailPrice === 'on' ? 'block' : 'none' ));
        cssRoot.style.setProperty('--detail-section-rent-color', htmldata.detail.detailPriceColor);
        cssRoot.style.setProperty('--detail-section-rent-ff', htmldata.detail.detailPriceFontFamily);
        cssRoot.style.setProperty('--detail-section-rent-fs', htmldata.detail.detailPriceFontSize + 'px');
        cssRoot.style.setProperty('--detail-section-rent-fw', (htmldata.detail.detailPriceFontBold === 'on' ? 'bold' : 'normal'));

        cssRoot.style.setProperty('--detail-section-date', (htmldata.detail.detailAvailable === 'on' ? 'block' : 'none' ));
        cssRoot.style.setProperty('--detail-section-date-color', htmldata.detail.detailAvailableColor);
        cssRoot.style.setProperty('--detail-section-date-ff', htmldata.detail.detailAvailableFontFamily);
        cssRoot.style.setProperty('--detail-section-date-fs', htmldata.detail.detailAvailableFontSize + 'px');
        cssRoot.style.setProperty('--detail-section-date-fw', (htmldata.detail.detailAvailableFontBold === 'on' ? 'bold' : 'normal'));

        cssRoot.style.setProperty('--detail-section-phone', (htmldata.detail.detailPhone === 'on' ? 'block' : 'none' ));
        cssRoot.style.setProperty('--detail-section-phone-color', htmldata.detail.detailPhoneColor);
        cssRoot.style.setProperty('--detail-section-phone-ff', htmldata.detail.detailPhoneFontFamily);
        cssRoot.style.setProperty('--detail-section-phone-fs', htmldata.detail.detailPhoneFontSize + 'px');
        cssRoot.style.setProperty('--detail-section-phone-fw', (htmldata.detail.detailPhoneFontBold === 'on' ? 'bold' : 'normal'));

        cssRoot.style.setProperty('--detail-section-showing', (htmldata.detail.detailShowingBtn === 'on' ? 'block' : 'none' ));
        cssRoot.style.setProperty('--detail-section-showing-color', htmldata.detail.detailShowingColor);
        cssRoot.style.setProperty('--detail-section-showing-bg', htmldata.detail.detailShowingBg);
        cssRoot.style.setProperty('--detail-section-showing-ff', htmldata.detail.detailShowingFontFamily);
        cssRoot.style.setProperty('--detail-section-showing-fs', htmldata.detail.detailShowingFontSize + 'px');
        cssRoot.style.setProperty('--detail-section-showing-fw', (htmldata.detail.detailShowingFontBold === 'on' ? 'bold' : 'normal'));

        cssRoot.style.setProperty('--detail-section-apply', (htmldata.detail.detailApplyBtn === 'on' ? 'block' : 'none' ));
        cssRoot.style.setProperty('--detail-section-apply-color', htmldata.detail.detailApplyColor);
        cssRoot.style.setProperty('--detail-section-apply-bg', htmldata.detail.detailApplyBg);
        cssRoot.style.setProperty('--detail-section-apply-ff', htmldata.detail.detailApplyFontFamily);
        cssRoot.style.setProperty('--detail-section-apply-fs', htmldata.detail.detailApplyFontSize + 'px');
        cssRoot.style.setProperty('--detail-section-apply-fw', (htmldata.detail.detailApplyFontBold === 'on' ? 'bold' : 'normal'));

        cssRoot.style.setProperty('--detail-section-contact', (htmldata.detail.detailContactBtn === 'on' ? 'block' : 'none' ));
        cssRoot.style.setProperty('--detail-section-contact-color', htmldata.detail.detailContactColor);
        cssRoot.style.setProperty('--detail-section-contact-bg', htmldata.detail.detailContactBg);
        cssRoot.style.setProperty('--detail-section-contact-ff', htmldata.detail.detailContactFontFamily);
        cssRoot.style.setProperty('--detail-section-contact-fs', htmldata.detail.detailContactFontSize + 'px');
        cssRoot.style.setProperty('--detail-section-contact-fw', (htmldata.detail.detailContactFontBold === 'on' ? 'bold' : 'normal'));
        
        
    }    

}

export default appfolioPreview;