import React, { Component } from 'react';

import { HexAlphaColorPicker, HexColorInput } from "react-colorful";

class Icon extends Component {

    constructor(props) {
        super(props);
    }

    setColorsAndPreview = async (type, color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('icon', type, color);
    }
    
    setIconColor = async (color) => {
        this.setColorsAndPreview('iconColor', color);
    }
    seticonBgColor = async (color) => {
        this.setColorsAndPreview('iconBg', color);
    }

    render(){

        const { widget, handleInputChange, toggleColorPicker } = this.props;
        const aw = widget.htmlData.accessibility;

        return (
            <div className="mjwi-acc-panel-wrapper">

                <div className='mjwi-row'>
                    <div className='mjwi-col50'>
                        <label>Color</label>
                    </div>
                    <div className='mjwi-col50 mjwi-clr-picker'>
                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: aw.icon.iconColor }}></span>
                        <HexAlphaColorPicker color={aw.icon.iconColor} onChange={this.setIconColor} style={{display: "none"}} className="mjwi-appfolio-color-picker" />
                        <HexColorInput color={aw.icon.iconColor} onChange={this.setIconColor} />
                    </div>
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col50'>
                        <label>Background</label>
                    </div>
                    <div className='mjwi-col50 mjwi-clr-picker'>
                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: aw.icon.iconBg }}></span>
                        <HexAlphaColorPicker color={aw.icon.iconBg} onChange={this.seticonBgColor} style={{display: "none"}} className="mjwi-appfolio-color-picker" />
                        <HexColorInput color={aw.icon.iconBg} onChange={this.seticonBgColor} />
                    </div>
                </div>

            </div>

        );
    }

}

export default Icon;
