import React, { Component } from 'react';
import Button from './Button';
import apiInstance from './apiInstance';
import InstallPopup from './InstallPopup';
import DeletePopup from './DeletePopup';

class Widget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            widgets: [],
            deletePopup: React.createRef(),
            installPopup: React.createRef()
        };
    }

    componentDidMount() {

        apiInstance.get('/widget/')
            .then(response => {
                this.setState({ widgets: response.data });
                // console.log(response.data )
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    deleteWidget = (widgetId) => {
        let updatedWidgets = this.state.widgets.filter((widget) => widget.id !== widgetId);

        this.setState((prevState) => {
            return({
                ...prevState,
                widgets: updatedWidgets
            })
        })
    }

    render() {
        let { widgets, deletePopup, installPopup } = this.state;
        return (
            <>
                <div className="mjwi-user-widgets-banner"><Button name="Add a New Widget" href={'/catalog'} /></div>
                <div className="mjwi-ct-list mjwi-row">
                    {widgets.map(widget => (
                        <div key={widget.id} className="mjwi-ct-card">
                            <div className="mjwi-ct-card-banner">
                                {/* <img src={"/images/" + widget.widgetType.name + '-banner.png'} className="mjwi-catalog-banner" /> */}
                            </div>
                             <div className="mjwi-ct-card-logoname">
                                <img src={"/images/" + widget.widgetType.name + '-logo.png'} className="mjwi-catalog-logo" />
                                <h3 className="mjwi-ct-card__title">{widget.widgetType.name}</h3>
                            </div>
                            <div className="mjwi-ct-card-desc">
                                <span className="mjwi-widget-title">
                                    <p class="mjwi-ct-card__content">Widget Name:&nbsp;{widget.title}</p>
                                </span>
                                <span className="mjwi-widget-text">
                                    {widget.text}
                                </span>
                            </div>
                            <div className="mjwi-ct-card-btns">
                                <Button name="Edit" href={'/editor?widgetId=' + widget.id} />
                                <Button name="Install" eventHandler={() => {installPopup.current.openPopup(widget, widget.widgetType.name)}} />
                                <Button name="Delete" eventHandler={() => {deletePopup.current.openPopup(widget.id)}} />
                            </div>
                        </div>
                    ))}
                </div>
                <DeletePopup ref={deletePopup} deleteWidgetHandler = {this.deleteWidget}/>
                <InstallPopup ref={installPopup}/>
            </>
        );
    }
}


export default Widget;
