const getSelectField = (name, options, selected, onchange) => {

    const correctOptions = options.map(option => (
        option == selected ? (
            <option key={option} selected="selected" value={option}>{option}</option>
        )
        :
        <option key={option} value={option}>{option}</option>
    ))

    return <select 
        name={name} 
        onChange={onchange}>
            {correctOptions}
        </select>;

}

export default getSelectField;
