import React, { Component } from 'react';

import getCheckboxField from '../../fields/checkbox';
import getSelectField from '../../fields/select';
import getFonts from '../../../Functions/config/fonts';
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";

class Header extends Component {

    constructor(props) {
        super(props);
    }

    setColorsAndPreview = async (type, color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('header', type, color);
    }

    setHeaderColor = async (color) => {
        await this.setColorsAndPreview('headercolor', color);
    }

    setHeaderBg = async (color) => {
        await this.setColorsAndPreview('headerbg', color);
    }

    render(){

        const { widget, handleInputChange, toggleColorPicker } = this.props;
        const { appfolio } = widget.htmlData;
        const fontOptions = getFonts();

        return(

            <div className="mjwi-acc-panel-wrapper">
                <label htmlFor="appfolioHeading">Heading:</label>
                <input
                    type="text"
                    name="appfolioHeading"
                    value={appfolio.header.appfolioHeading}
                    onChange={handleInputChange}
                />

                <div className='mjwi-row'>    
                    <div className='mjwi-col33'>
                            <label>
                                Font Family
                            </label>                                    
                        </div>
                        <div className='mjwi-col66'>
                            {getSelectField('headerFontFamily', fontOptions, appfolio.header.headerFontFamily, handleInputChange)}
                        </div>
                </div>

                <div className='mjwi-row'>    
                    <div className='mjwi-col33 mjwi-row'>
                        <label htmlFor="headerFontSize">Size</label>&nbsp;
                        <input
                            type="number"
                            name="headerFontSize"
                            value={appfolio.header.headerFontSize}
                            onChange={handleInputChange}
                        />&nbsp;px
                    </div>
                    <div className='mjwi-col25'>
                        <label>
                            {getCheckboxField('headerFontBold', appfolio.header.headerFontBold, handleInputChange)}
                            Bold
                        </label>                                  
                    </div>
                    <div className='mjwi-col25'>
                        <label>
                            {getCheckboxField('headerFontItalic', appfolio.header.headerFontItalic, handleInputChange)}
                            Italic
                        </label>                                  
                    </div>                          
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col33'>
                        <label>Color</label>
                    </div>
                    <div className='mjwi-col66 mjwi-clr-picker'>
                        
                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.header.headercolor }}></span>
                        <HexAlphaColorPicker color={appfolio.header.headercolor} onChange={this.setHeaderColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                        <HexColorInput color={appfolio.header.headercolor} onChange={this.setHeaderColor} />
                    </div>
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col33'>
                        <label>Background</label>
                    </div>
                    <div className='mjwi-col66 mjwi-clr-picker'>
                        
                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.header.headerbg }}></span>
                        <HexAlphaColorPicker color={appfolio.header.headerbg} onChange={this.setHeaderBg} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                        <HexColorInput color={appfolio.header.headerbg} onChange={this.setHeaderBg} />

                    </div>
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col100'>
                        <label htmlFor="appfolioHeading">Show/Hide Filters</label>
                    </div>
                </div>
                <div className="mjwi-apfl-display-filters">
                    <div className='mjwi-row'>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('search', appfolio.appfolioDisplayFilters.search, handleInputChange)}
                                Search
                            </label>                                    
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('minRent', appfolio.appfolioDisplayFilters.minRent, handleInputChange)}
                                Min Rent
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('maxRent', appfolio.appfolioDisplayFilters.maxRent, handleInputChange)}
                                Max Rent
                            </label>
                        </div>
                    </div>
                    <div className='mjwi-row'>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('beds', appfolio.appfolioDisplayFilters.beds, handleInputChange)}
                                Beds
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('baths', appfolio.appfolioDisplayFilters.baths, handleInputChange)}
                                Baths
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('cities', appfolio.appfolioDisplayFilters.cities, handleInputChange)}
                                Cities
                            </label>
                        </div>
                        
                    </div>
                    <div className='mjwi-row'>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('zip', appfolio.appfolioDisplayFilters.zip, handleInputChange)}
                                Zip
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('cats', appfolio.appfolioDisplayFilters.cats, handleInputChange)}
                                Cats
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('dogs', appfolio.appfolioDisplayFilters.dogs, handleInputChange)}
                                Dogs
                            </label>
                        </div>
                    </div>

                    <div className='mjwi-row'>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('sorting', appfolio.appfolioDisplayFilters.sorting, handleInputChange)}
                                Sorting
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('desiredMoveIn', appfolio.appfolioDisplayFilters.desiredMoveIn, handleInputChange)}
                                Move-In
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                        </div>
                    </div>
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col33'>
                        <label>Sort order</label>
                    </div>
                    <div className='mjwi-col66'>
                        <select
                            id="appfolioSortOrder"
                            name="appfolioSortOrder"
                            value={appfolio.appfolioSortOrder}
                            onChange={handleInputChange}
                        >
                            <option value="date_posted">Most Recent</option>
                            <option value="rent_asc">Rent (Low to High)</option>
                            <option value="rent_desc">Rent (High to Low)</option>
                            <option value="bedrooms_asc">Bedrooms (ASC)</option>
                            <option value="bedrooms_desc">Bedrooms (DESC)</option>
                            <option value="availability">Availability</option>
                        </select>
                    </div>
                </div>
            </div>

        );


    }



}

export default Header;
