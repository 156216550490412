import React, { Component } from 'react';
import Widget from './Widget';

class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="mjwi-widgets-wrapper">
        <Widget />
      </div>
    );
  }
}

export default Home;
