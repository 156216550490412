import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';

import getCheckboxField from '../../fields/checkbox';
import getSelectField from '../../fields/select';
import getRadioField from '../../fields/radio';

import getFonts from '../../../Functions/config/fonts';
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";

class Detail extends Component {

    constructor(props) {
        super(props);
    }

    setDetailSectionBg = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailSectionBg', color);
    }
    setDetailSectionTextColor = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailSectionTextColor', color);
    }
    setDetailAddressColor = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailAddressColor', color);
    }
    setDetailPriceColor = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailPriceColor', color);
    }
    setDetailAvailableColor = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailAvailableColor', color);
    }
    setDetailPhoneColor = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailPhoneColor', color);
    }
    setDetailShowingColor = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailShowingColor', color);
    }
    setDetailShowingBg = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailShowingBg', color);
    }
    setDetailApplyColor = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailApplyColor', color);
    }
    setDetailApplyBg = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailApplyBg', color);
    }
    setDetailContactColor = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailContactColor', color);
    }
    setDetailContactBg = async (color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('detail', 'detailContactBg', color);
    }

    render(){
        
        const { widget, handleInputChange, toggleColorPicker } = this.props;
        const { appfolio } = widget.htmlData;
        const fontOptions = getFonts();

        return(

            <div className="mjwi-acc-panel-wrapper">

                <div className='mjwi-row'>
                    <div className='mjwi-col75'>
                        <label>
                            Open in Separate Page
                        </label>
                    </div>
                    <div className='mjwi-col25'>
                        {getCheckboxField('detailOpenSeparate', appfolio.detail.detailOpenSeparate, handleInputChange)}
                    </div>
                </div>

                <div className='mjwi-row'>    
                    <div className='mjwi-col33'>
                        <label>
                            Layout
                        </label>
                    </div>
                    <div className='mjwi-col66'>
                        <div className='mjwi-row mjwi-flex-end'>
                            <div className='mjwi-col25 mjwi-radio-svg'>
                                <label>
                                    {getRadioField('detailTemplate', 'full', appfolio.detail.detailTemplate, handleInputChange)}
                                    <svg width="50px" height="50px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 9H21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </label>
                            </div>
                            <div className='mjwi-col25 mjwi-radio-svg'>
                                <label>
                                    {getRadioField('detailTemplate', 'half', appfolio.detail.detailTemplate, handleInputChange)}
                                    <svg width="50px" height="50px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 3V21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col50'>
                        <label>Background Color</label>
                    </div>
                    <div className='mjwi-col50 mjwi-clr-picker'>
                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailSectionBg }}></span>
                        <HexAlphaColorPicker color={appfolio.detail.detailSectionBg} onChange={this.setDetailSectionBg} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                        <HexColorInput color={appfolio.detail.detailSectionBg} onChange={this.setDetailSectionBg} />
                    </div>
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col50'>
                        <label>Text Color</label>
                    </div>
                    <div className='mjwi-col50 mjwi-clr-picker'>
                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailSectionTextColor }}></span>
                        <HexAlphaColorPicker color={appfolio.detail.detailSectionTextColor} onChange={this.setDetailSectionTextColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                        <HexColorInput color={appfolio.detail.detailSectionTextColor} onChange={this.setDetailSectionTextColor} />
                    </div>
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col33'>
                        <label>Font Family</label>
                    </div>
                    <div className='mjwi-col66'>
                        {getSelectField('detailSectionFF', fontOptions, appfolio.detail.detailSectionFF, handleInputChange)}
                    </div>
                </div>

                <Accordion allowZeroExpanded={true}>
                    <AccordionItem className='mjwi-sub-accordion'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Address
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            <div className='mjwi-acc-panel-wrapper'>
                                <div className='mjwi-row'>
                                    <div className='mjwi-col25'>
                                        <label>
                                            {getCheckboxField('detailAddress', appfolio.detail.detailAddress, handleInputChange)}
                                            Show
                                        </label>
                                    </div>
                                    <div className='mjwi-col75'>
                                    {
                                        appfolio.detail.detailAddress === 'on' && (
                                            <div className='mjwi-row'>    
                                                <div className='mjwi-col50 mjwi-row'>
                                                    <label>Size</label>&nbsp;
                                                    <input
                                                        type="number"
                                                        name="detailAddressFontSize"
                                                        value={appfolio.detail.detailAddressFontSize}
                                                        onChange={handleInputChange}
                                                    />&nbsp;px
                                                </div>
                                                <div className='mjwi-col33'>
                                                    <label>
                                                        {getCheckboxField('detailAddressFontBold', appfolio.detail.detailAddressFontBold, handleInputChange)}
                                                        Bold
                                                    </label>                                  
                                                </div>                          
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>

                                {
                                    appfolio.detail.detailAddress === 'on' && (
                                        <>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col50'>
                                                <label>Color</label>
                                            </div>
                                            <div className='mjwi-col50 mjwi-clr-picker'>
                                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailAddressColor }}></span>
                                                <HexAlphaColorPicker color={appfolio.detail.detailAddressColor} onChange={this.setDetailAddressColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                                <HexColorInput color={appfolio.detail.detailAddressColor} onChange={this.setDetailAddressColor} />
                                            </div>
                                        </div>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col33'>
                                                <label>Font Family</label>
                                            </div>
                                            <div className='mjwi-col66'>
                                                {getSelectField('detailAddressFontFamily', fontOptions, appfolio.detail.detailAddressFontFamily, handleInputChange)}
                                            </div>
                                        </div>
                                        </>
                                    )
                                }
                            </div>

                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className='mjwi-sub-accordion'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Rent
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            <div className='mjwi-acc-panel-wrapper'>
                                <div className='mjwi-row'>
                                    <div className='mjwi-col25'>
                                        <label>
                                            {getCheckboxField('detailPrice', appfolio.detail.detailPrice, handleInputChange)}
                                            Show
                                        </label>
                                    </div>
                                    <div className='mjwi-col75'>
                                    {
                                        appfolio.detail.detailPrice === 'on' && (
                                            <div className='mjwi-row'>    
                                                <div className='mjwi-col50 mjwi-row'>
                                                    <label>Size</label>&nbsp;
                                                    <input
                                                        type="number"
                                                        name="detailPriceFontSize"
                                                        value={appfolio.detail.detailPriceFontSize}
                                                        onChange={handleInputChange}
                                                    />&nbsp;px
                                                </div>
                                                <div className='mjwi-col33'>
                                                    <label>
                                                        {getCheckboxField('detailPriceFontBold', appfolio.detail.detailPriceFontBold, handleInputChange)}
                                                        Bold
                                                    </label>                                  
                                                </div>                          
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                                {
                                    appfolio.detail.detailPrice === 'on' && (
                                        <>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col50'>
                                                <label>Color</label>
                                            </div>
                                            <div className='mjwi-col50 mjwi-clr-picker'>
                                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailPriceColor }}></span>
                                                <HexAlphaColorPicker color={appfolio.detail.detailPriceColor} onChange={this.setDetailPriceColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                                <HexColorInput color={appfolio.detail.detailPriceColor} onChange={this.setDetailPriceColor} />
                                            </div>
                                        </div>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col33'>
                                                <label>Font Family</label>
                                            </div>
                                            <div className='mjwi-col66'>
                                                {getSelectField('detailPriceFontFamily', fontOptions, appfolio.detail.detailPriceFontFamily, handleInputChange)}
                                            </div>
                                        </div>
                                        </>
                                    )
                                }
                            </div>

                            
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className='mjwi-sub-accordion'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Available Date
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            <div className='mjwi-acc-panel-wrapper'>
                                <div className='mjwi-row'>
                                    <div className='mjwi-col25'>
                                        <label>
                                            {getCheckboxField('detailAvailable', appfolio.detail.detailAvailable, handleInputChange)}
                                            Show
                                        </label>
                                    </div>
                                    <div className='mjwi-col75'>
                                    {
                                        appfolio.detail.detailAvailable === 'on' && (
                                            <div className='mjwi-row'>    
                                                <div className='mjwi-col50 mjwi-row'>
                                                    <label>Size</label>&nbsp;
                                                    <input
                                                        type="number"
                                                        name="detailAvailableFontSize"
                                                        value={appfolio.detail.detailAvailableFontSize}
                                                        onChange={handleInputChange}
                                                    />&nbsp;px
                                                </div>
                                                <div className='mjwi-col33'>
                                                    <label>
                                                        {getCheckboxField('detailAvailableFontBold', appfolio.detail.detailAvailableFontBold, handleInputChange)}
                                                        Bold
                                                    </label>                                  
                                                </div>                          
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                                {
                                    appfolio.detail.detailAvailable === 'on' && (
                                        <>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col50'>
                                                <label>Color</label>
                                            </div>
                                            <div className='mjwi-col50 mjwi-clr-picker'>
                                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailAvailableColor }}></span>
                                                <HexAlphaColorPicker color={appfolio.detail.detailAvailableColor} onChange={this.setDetailAvailableColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                                <HexColorInput color={appfolio.detail.detailAvailableColor} onChange={this.setDetailAvailableColor} />
                                            </div>
                                        </div>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col33'>
                                                <label>Font Family</label>
                                            </div>
                                            <div className='mjwi-col66'>
                                                {getSelectField('detailAvailableFontFamily', fontOptions, appfolio.detail.detailAvailableFontFamily, handleInputChange)}
                                            </div>
                                        </div>
                                        </>
                                    )
                                }
                            </div>

                            
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className='mjwi-sub-accordion'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Phone
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            <div className='mjwi-acc-panel-wrapper'>
                                <div className='mjwi-row'>
                                    <div className='mjwi-col25'>
                                        <label>
                                            {getCheckboxField('detailPhone', appfolio.detail.detailPhone, handleInputChange)}
                                            Show
                                        </label>
                                    </div>
                                    <div className='mjwi-col75'>
                                    {
                                        appfolio.detail.detailPhone === 'on' && (
                                            <div className='mjwi-row'>    
                                                <div className='mjwi-col50 mjwi-row'>
                                                    <label>Size</label>&nbsp;
                                                    <input
                                                        type="number"
                                                        name="detailPhoneFontSize"
                                                        value={appfolio.detail.detailPhoneFontSize}
                                                        onChange={handleInputChange}
                                                    />&nbsp;px
                                                </div>
                                                <div className='mjwi-col33'>
                                                    <label>
                                                        {getCheckboxField('detailPhoneFontBold', appfolio.detail.detailPhoneFontBold, handleInputChange)}
                                                        Bold
                                                    </label>                                  
                                                </div>                          
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                                {
                                    appfolio.detail.detailPhone === 'on' && (
                                        <>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col50'>
                                                <label>Color</label>
                                            </div>
                                            <div className='mjwi-col50 mjwi-clr-picker'>
                                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailPhoneColor }}></span>
                                                <HexAlphaColorPicker color={appfolio.detail.detailPhoneColor} onChange={this.setDetailPhoneColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                                <HexColorInput color={appfolio.detail.detailPhoneColor} onChange={this.setDetailPhoneColor} />
                                            </div>
                                        </div>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col33'>
                                                <label>Font Family</label>
                                            </div>
                                            <div className='mjwi-col66'>
                                                {getSelectField('detailPhoneFontFamily', fontOptions, appfolio.detail.detailPhoneFontFamily, handleInputChange)}
                                            </div>
                                        </div>
                                        </>
                                    )
                                }
                            </div>

                            
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className='mjwi-sub-accordion'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Schedule Showing Button
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            <div className='mjwi-acc-panel-wrapper'>
                                <div className='mjwi-row'>
                                    <div className='mjwi-col25'>
                                        <label>
                                            {getCheckboxField('detailShowingBtn', appfolio.detail.detailShowingBtn, handleInputChange)}
                                            Show
                                        </label>
                                    </div>
                                    <div className='mjwi-col75'>
                                    {
                                        appfolio.detail.detailShowingBtn === 'on' && (
                                            <div className='mjwi-row'>    
                                                <div className='mjwi-col50 mjwi-row'>
                                                    <label>Size</label>&nbsp;
                                                    <input
                                                        type="number"
                                                        name="detailShowingFontSize"
                                                        value={appfolio.detail.detailShowingFontSize}
                                                        onChange={handleInputChange}
                                                    />&nbsp;px
                                                </div>
                                                <div className='mjwi-col33'>
                                                    <label>
                                                        {getCheckboxField('detailShowingFontBold', appfolio.detail.detailShowingFontBold, handleInputChange)}
                                                        Bold
                                                    </label>                                  
                                                </div>                          
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                                {
                                    appfolio.detail.detailShowingBtn === 'on' && (
                                        <>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col50'>
                                                <label>Color</label>
                                            </div>
                                            <div className='mjwi-col50 mjwi-clr-picker'>
                                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailShowingColor }}></span>
                                                <HexAlphaColorPicker color={appfolio.detail.detailShowingColor} onChange={this.setDetailShowingColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                                <HexColorInput color={appfolio.detail.detailShowingColor} onChange={this.setDetailShowingColor} />
                                            </div>
                                        </div>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col50'>
                                                <label>Background Color</label>
                                            </div>
                                            <div className='mjwi-col50 mjwi-clr-picker'>
                                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailShowingBg }}></span>
                                                <HexAlphaColorPicker color={appfolio.detail.detailShowingBg} onChange={this.setDetailShowingBg} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                                <HexColorInput color={appfolio.detail.detailShowingBg} onChange={this.setDetailShowingBg} />
                                            </div>
                                        </div>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col33'>
                                                <label>Font Family</label>
                                            </div>
                                            <div className='mjwi-col66'>
                                                {getSelectField('detailShowingFontFamily', fontOptions, appfolio.detail.detailShowingFontFamily, handleInputChange)}
                                            </div>
                                        </div>
                                        </>
                                    )
                                }
                            </div>

                            
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className='mjwi-sub-accordion'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Apply Button
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            <div className='mjwi-acc-panel-wrapper'>
                                <div className='mjwi-row'>
                                    <div className='mjwi-col25'>
                                        <label>
                                            {getCheckboxField('detailApplyBtn', appfolio.detail.detailApplyBtn, handleInputChange)}
                                            Show
                                        </label>
                                    </div>
                                    <div className='mjwi-col75'>
                                    {
                                        appfolio.detail.detailApplyBtn === 'on' && (
                                            <div className='mjwi-row'>    
                                                <div className='mjwi-col50 mjwi-row'>
                                                    <label>Size</label>&nbsp;
                                                    <input
                                                        type="number"
                                                        name="detailApplyFontSize"
                                                        value={appfolio.detail.detailApplyFontSize}
                                                        onChange={handleInputChange}
                                                    />&nbsp;px
                                                </div>
                                                <div className='mjwi-col33'>
                                                    <label>
                                                        {getCheckboxField('detailApplyFontBold', appfolio.detail.detailApplyFontBold, handleInputChange)}
                                                        Bold
                                                    </label>                                  
                                                </div>                          
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                                {
                                    appfolio.detail.detailApplyBtn === 'on' && (
                                        <>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col50'>
                                                <label>Color</label>
                                            </div>
                                            <div className='mjwi-col50 mjwi-clr-picker'>
                                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailApplyColor }}></span>
                                                <HexAlphaColorPicker color={appfolio.detail.detailApplyColor} onChange={this.setDetailApplyColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                                <HexColorInput color={appfolio.detail.detailApplyColor} onChange={this.setDetailApplyColor} />
                                            </div>
                                        </div>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col50'>
                                                <label>Background Color</label>
                                            </div>
                                            <div className='mjwi-col50 mjwi-clr-picker'>
                                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailApplyBg }}></span>
                                                <HexAlphaColorPicker color={appfolio.detail.detailApplyBg} onChange={this.setDetailApplyBg} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                                <HexColorInput color={appfolio.detail.detailApplyBg} onChange={this.setDetailApplyBg} />
                                            </div>
                                        </div>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col33'>
                                                <label>Font Family</label>
                                            </div>
                                            <div className='mjwi-col66'>
                                                {getSelectField('detailApplyFontFamily', fontOptions, appfolio.detail.detailApplyFontFamily, handleInputChange)}
                                            </div>
                                        </div>
                                        </>
                                    )
                                }
                            </div>

                            
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className='mjwi-sub-accordion'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Contact Button
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            <div className='mjwi-acc-panel-wrapper'>
                                <div className='mjwi-row'>
                                    <div className='mjwi-col25'>
                                        <label>
                                            {getCheckboxField('detailContactBtn', appfolio.detail.detailContactBtn, handleInputChange)}
                                            Show
                                        </label>
                                    </div>
                                    <div className='mjwi-col75'>
                                    {
                                        appfolio.detail.detailContactBtn === 'on' && (
                                            <div className='mjwi-row'>    
                                                <div className='mjwi-col50 mjwi-row'>
                                                    <label>Size</label>&nbsp;
                                                    <input
                                                        type="number"
                                                        name="detailContactFontSize"
                                                        value={appfolio.detail.detailContactFontSize}
                                                        onChange={handleInputChange}
                                                    />&nbsp;px
                                                </div>
                                                <div className='mjwi-col33'>
                                                    <label>
                                                        {getCheckboxField('detailContactFontBold', appfolio.detail.detailContactFontBold, handleInputChange)}
                                                        Bold
                                                    </label>                                  
                                                </div>                          
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                                {
                                    appfolio.detail.detailContactBtn === 'on' && (
                                        <>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col50'>
                                                <label>Color</label>
                                            </div>
                                            <div className='mjwi-col50 mjwi-clr-picker'>
                                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailContactColor }}></span>
                                                <HexAlphaColorPicker color={appfolio.detail.detailContactColor} onChange={this.setDetailContactColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                                <HexColorInput color={appfolio.detail.detailContactColor} onChange={this.setDetailContactColor} />
                                            </div>
                                        </div>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col50'>
                                                <label>Background Color</label>
                                            </div>
                                            <div className='mjwi-col50 mjwi-clr-picker'>
                                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.detail.detailContactBg }}></span>
                                                <HexAlphaColorPicker color={appfolio.detail.detailContactBg} onChange={this.setDetailContactBg} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                                <HexColorInput color={appfolio.detail.detailContactBg} onChange={this.setDetailContactBg} />
                                            </div>
                                        </div>
                                        <div className='mjwi-row'>
                                            <div className='mjwi-col33'>
                                                <label>Font Family</label>
                                            </div>
                                            <div className='mjwi-col66'>
                                                {getSelectField('detailContactFontFamily', fontOptions, appfolio.detail.detailContactFontFamily, handleInputChange)}
                                            </div>
                                        </div>
                                        </>
                                    )
                                }
                            </div>

                            
                        </AccordionItemPanel>
                    </AccordionItem>

                </Accordion>

               

            </div>

        );

    }

}

export default Detail;