import React, { Component } from 'react';
import Button from '../../Button';
import apiInstance from '../../apiInstance';

class AddWidgetPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            widget: {
                id: props.data?.widget?.id || '',
                name: props.data?.widget?.name || '',
                price: props.data?.widget?.price || '0',
                desc: props.data?.widget?.desc || '',
                category: props.data?.widget?.category || 'feeds',
                stripePriceId: props.data?.widget?.stripePriceId || '',
                stripeProductId: props.data?.widget?.stripeProductId || '',
                scripts: {
                    main: props.data?.widget?.scripts?.main || '',
                    lib: props.data?.widget?.scripts?.lib || ''
                }
            },
            type: props?.type || 'add',
            fieldErrors: {},
        };
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState((prevState) => ({
            widget: {
                ...prevState.widget,
                [name]: value
            }
        }));
    }

    handleScriptChange = (e) => {
        const { name, value } = e.target;

        this.setState((prevState) => ({
            widget: {
                ...prevState.widget,
                scripts: {
                    ...prevState.widget.scripts,
                    [name]: value
                }
            }
        }));
    }

    addWidget = () => {
        let {widget, type} = this.state;
        if(type == 'update') {

            apiInstance.put(`/devadmin/availableWidget/${widget.id}`, widget)
            .then((response) => {
                this.props.updateAvailableWidget(response.data.availableWidget)
                this.props.closePopup();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 400 && error.response.data.fieldErrors) {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                fieldErrors: error.response.data.fieldErrors
                            })
                        })
                    }
                    else {
                        this.props.updateError(error.response.data);
                    }
                }
            });

        } else {
            
            apiInstance.post(`/devadmin/availableWidget`, widget)
            .then((response) => {
                this.props.addAvailableWidget(response.data.availableWidget)
                this.props.closePopup();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 400 && error.response.data.fieldErrors) {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                fieldErrors: error.response.data.fieldErrors
                            })
                        })
                    }
                    else {
                        this.props.updateError(error.response.data);
                    }
                }
            });
        }
    }

    render() {
        const { type, fieldErrors } = this.state;
        const { name, price, desc, category, stripeProductId, stripePriceId, scripts } = this.state.widget;

        return (
                <div className='mjwidev-add-widget-pp mjwidev-pp'>
                    <h2>{type === 'add' ? 'ADD WIDGET' : 'UPDATE WIDGET'}</h2>
                    <form type='#'>
                        <div className='mjwidev-input-field'>
                            <input
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={name}
                                onChange={this.handleInputChange}
                            />
                             {fieldErrors.name && <span className="mjwi-error">{fieldErrors.name}</span>}
                        </div>

                        <div className='mjwidev-input-field'>
                            <input
                                placeholder="Widget description"
                                name="desc"
                                value={desc}
                                onChange={this.handleInputChange}
                            />
                        </div>

                        <div className='mjwidev-input-field'>
                            <input
                                type="number"
                                placeholder="Price"
                                name="price"
                                value={price}
                                onChange={this.handleInputChange}
                            />
                            {fieldErrors.price && <span className="mjwi-error">{fieldErrors.price}</span>}
                        </div>

                        <div className='mjwidev-input-field'>
                            <select
                                name="category"
                                value={category}
                                onChange={this.handleInputChange}
                            >
                                
                                <option value={"feeds"}>Feeds</option>
                                <option value={"files"}>Files</option>
                                <option value={"listings"}>Listings</option>
                                <option value={"reviews"}>Reviews</option>
                                <option value={"misc"}>Miscellaneous</option>
                            </select>
                            {fieldErrors.category && <span className="mjwi-error">{fieldErrors.category}</span>}
                        </div>

                        <div className='mjwidev-input-field'>
                            <input
                                type="text"
                                placeholder="Stripe Price Id"
                                name="stripePriceId"
                                value={stripePriceId}
                                onChange={this.handleInputChange}
                            />
                            {fieldErrors.stripePriceId && <span className="mjwi-error">{fieldErrors.stripePriceId}</span>}
                        </div>

                        <div className='mjwidev-input-field'>
                            <input
                                type="text"
                                placeholder="Stripe Product Id"
                                name="stripeProductId"
                                value={stripeProductId}
                                onChange={this.handleInputChange}
                            />
                            {fieldErrors.stripeProductId && <span className="mjwi-error">{fieldErrors.stripeProductId}</span>}
                        </div>

                        <div className='mjwidev-input-field'>
                            <input
                                type="text"
                                placeholder="Main script"
                                name="main"
                                value={scripts.main}
                                onChange={this.handleScriptChange}
                            />
                            {fieldErrors?.scripts?.main && <span className="mjwi-error">{fieldErrors.scripts.main}</span>}
                        </div>

                        <div className='mjwidev-input-field'>
                            <input
                                type="text"
                                placeholder="Lib script"
                                name="lib"
                                value={scripts.lib}
                                onChange={this.handleScriptChange}
                            />
                            {fieldErrors?.scripts?.lib && <span className="mjwi-error">{fieldErrors.scripts.lib}</span>}
                        </div>
                        
                    </form>
                    <Button name={type === 'add' ? 'Add' : 'Update'} eventHandler={this.addWidget} />
                </div>
        );
    }
}

export default AddWidgetPopup;
