import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';

import toggleColorPicker from '../../Functions/toggleColorPicker';
import getSelectField from '../fields/select';
import getRadioField from '../fields/radio';

import Icon from './accessibility/icon';
import Toolbox from './accessibility/toolbox';

class Accessibility extends Component {
    constructor(props) {
        super(props);

        const { widget } = this.props;
        const { htmlData } = widget;

        this.state = {
            widget: {
                htmlData: {
                    accessibility: {
                        icon:{
                            iconSvg: htmlData?.accessibility?.icon?.iconSvg || '1',
                            iconPosition: htmlData?.accessibility?.icon?.iconPosition || 'right',
                            iconColor: htmlData?.accessibility?.icon?.iconColor || '#ffffff',
                            iconBg: htmlData?.accessibility?.icon?.iconBg || '#f6690b',
                        },
                        toolbox:{
                            toolboxTitle: htmlData?.accessibility?.toolbox?.toolboxTitle || 'Accessibility Menu',
                            toolboxTextColor: htmlData?.accessibility?.toolbox?.toolboxTextColor || '#ffffff',
                            toolboxBg: htmlData?.accessibility?.toolbox?.toolboxBg || '#003333',
                            groupTextColor: htmlData?.accessibility?.toolbox?.groupTextColor || '#003333',
                            groupBg: htmlData?.accessibility?.toolbox?.groupBg || '#ffffff',
                            groupHoverColor: htmlData?.accessibility?.toolbox?.groupHoverColor || '#8b0000',
                            groupHoverBg: htmlData?.accessibility?.toolbox?.groupHoverBg || '#fffccc',
                        },
                        domainUrl: decodeURIComponent(htmlData?.accessibility?.domainUrl || ''),
                    },
                },
                widgetType: widget.widgetType.id,
                widgetTypeName: widget.widgetType.name,
                widgetTypeScripts: widget.widgetType.scripts,
                title: widget.title ? widget.title : '',
                expires: widget.expires ? widget.expires : '',
                isUrlChanged: true,
            },
            previewHtml: '',
            fieldErrors: '',
        };

    }

    handleInputChange = async (e) => {
        const { type, name, value, checked } = e.target;

        await this.setState((prevState) => ({
            widget: {
                ...prevState.widget,
                [name]: value,
                isUrlChanged: false,
                htmlData: {
                    ...prevState.widget.htmlData,
                    accessibility: {
                        ...prevState.widget.htmlData.accessibility,
                        [name]: value,
                        icon: {
                            ...prevState.widget.htmlData.accessibility.icon,
                            [name]: (type == 'checkbox' ? (checked ? 'on' : 'off'): value),
                        },
                        toolbox: {
                            ...prevState.widget.htmlData.accessibility.toolbox,
                            [name]: (type == 'checkbox' ? (checked ? 'on' : 'off'): value),
                        },
                    },
                },
            },
        }));

        if(name !== 'title'){
            const { previewWidget } = this.props;
            await previewWidget(this.state.widget);
        }
        else{
            const { updateWrapperWidget } = this.props;
            await updateWrapperWidget(this.state.widget);
        }

    };

    async componentDidMount() {
        const { previewWidget } = this.props;
        await previewWidget(this.state.widget);
    }

    // common function for color pickers
    setColorsAndPreview = async (section, stateItem, value) => {
        this.setState((prevState) => ({
            widget: {
                ...prevState.widget,
                isUrlChanged: false,
                htmlData: {
                    ...prevState.widget.htmlData,
                    accessibility: {
                        ...prevState.widget.htmlData.accessibility,
                        [section]:{
                            ...prevState.widget.htmlData.accessibility[section],
                            [stateItem] : value,
                        }
                    },
                },
            },
        }));

        const { previewWidget } = this.props;
        // color is being changed from picker and input field. state update is being updated with previous color value. So, let's prepare widget with latest value.
        const widgetTosend = this.state.widget;
        widgetTosend.htmlData.accessibility[section][stateItem] = value;
        await previewWidget(widgetTosend); // this.state.widget
    }

    render() {

        const { widget } = this.state;
        const { selectedEditor } = this.props;

        const { title, htmlData} = widget;

        const icon = htmlData.accessibility.icon;
        const domainUrl = htmlData.accessibility.domainUrl;

        return (
            <div className="mjwi-editor-main-inner">

                {selectedEditor === 'Builder' && (
                    <div className="mjwi-editor-builder">

                        <label htmlFor="title">Widget Name</label>
                        <input
                            type="text"
                            name="title"
                            value={title}
                            placeholder="Title"
                            onChange={this.handleInputChange}
                        />

                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Icon</label>
                            </div>
                            <div className='mjwi-col50'>

                                <div className='mjwi-row'>
                                    <div className='mjwi-col33 mjwi-radio-svg'>
                                        <label>
                                            {getRadioField('iconSvg', '1', icon.iconSvg, this.handleInputChange)}
                                            <svg width="32px" height="32px" fill="#ffffff" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M13.4338078,4.18678 C13.5358078,4.65874 13.2359078,5.12406 12.7640078,5.22611 L10.0455078,5.81388 C9.58532779,5.91339 9.25686779,6.32045 9.25686779,6.7913 L9.25686779,7.67098 C9.25686779,8.62688 9.42818779,9.57506 9.76270779,10.4705 L11.3873078,14.8195 C11.5563078,15.2718 11.3266078,15.7755 10.8742078,15.9445 C10.4219078,16.1135 9.91820779,15.8837 9.74930779,15.4314 L8.19520779,11.2713 C8.03331779,10.8379 7.42032779,10.8379 7.25843779,11.2713 L5.70436779,15.4314 C5.53538779,15.8837 5.03171779,16.1135 4.57938779,15.9445 C4.12705779,15.7755 3.89734779,15.2718 4.06632779,14.8195 L5.69097779,10.4704 C6.02549779,9.57496 6.19681779,8.62678 6.19681779,7.67088 L6.19681779,6.79129 C6.19681779,6.32044 5.86835779,5.91338 5.40814779,5.81388 L2.68971779,5.22611 C2.21775779,5.12406 1.91788779,4.65874 2.01992779,4.18678 C2.12197779,3.71483 2.58729779,3.41495 3.05925779,3.517 L6.88151779,4.34344 C7.43863779,4.46389 8.01506779,4.46389 8.57218779,4.34344 L12.3944078,3.517 C12.8664078,3.41495 13.3317078,3.71483 13.4338078,4.18678 Z M7.68341779,0 C8.6491429,0 9.43201779,0.782874886 9.43201779,1.7486 C9.43201779,2.71432511 8.6491429,3.4972 7.68341779,3.4972 C6.71769267,3.4972 5.93481779,2.71432511 5.93481779,1.7486 C5.93481779,0.782874886 6.71769267,0 7.68341779,0 Z"/></svg>
                                        </label>
                                    </div>
                                    <div className='mjwi-col33 mjwi-radio-svg'>
                                        <label>
                                            {getRadioField('iconSvg', '2', icon.iconSvg, this.handleInputChange)}
                                            <svg fill="#ffffff" width="32px" height="32px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.77,31.94a7.31,7.31,0,0,1-5.7-11.88L7.65,18.64a9.3,9.3,0,0,0,13.1,13.11l-1.42-1.42A7.29,7.29,0,0,1,14.77,31.94Z" class="clr-i-solid clr-i-solid-path-1"></path>
                                                <path d="M26.65,2.1a3.12,3.12,0,1,0,3.11,3.12A3.12,3.12,0,0,0,26.65,2.1Z" class="clr-i-solid clr-i-solid-path-2"></path>
                                                <path d="M26.81,18.18H21.47q-.31-.33-.66-.63l4.38-4.86a2.14,2.14,0,0,0-.53-3.27L20.9,7.23l0,0L17.05,5.07a1,1,0,0,0-1.11.08L11.15,8.9a1,1,0,0,0,1.23,1.58l4.27-3.34,2.87,1.63L13.6,15.39a9.33,9.33,0,0,0-4.44,1.82l1.42,1.43A7.3,7.3,0,0,1,20.75,28.81l1.43,1.43A9.27,9.27,0,0,0,23,20.18h2.74l-.77,6.51a1,1,0,0,0,.87,1.11h.12a1,1,0,0,0,1-.88l.9-7.62a1,1,0,0,0-.25-.78A1,1,0,0,0,26.81,18.18Z" class="clr-i-solid clr-i-solid-path-3"></path>
                                            </svg>
                                        </label>
                                    </div>
                                    <div className='mjwi-col33 mjwi-radio-svg'>
                                        <label>
                                            {getRadioField('iconSvg', '3', icon.iconSvg, this.handleInputChange)}
                                            <svg fill="#fff" width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9.5,3.5A2.5,2.5,0,1,1,12,6,2.5,2.5,0,0,1,9.5,3.5ZM20,7H4A1,1,0,0,0,4,9H9V22a1,1,0,0,0,2,0V15h2v7a1,1,0,0,0,2,0V9h5a1,1,0,0,0,0-2Z"/></svg>
                                        </label>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className='mjwi-row'>    
                            <div className='mjwi-col50'>
                                <label>
                                    Position
                                </label>                                    
                            </div>
                            <div className='mjwi-col50'>
                                {getSelectField('iconPosition', ['left', 'right'], icon.iconPosition, this.handleInputChange)}
                            </div>
                        </div>

                        <label>Website URL</label>
                        <input
                            type="text"
                            name="domainUrl"
                            value={domainUrl}
                            placeholder="Your website URL"
                            onChange={this.handleInputChange}
                        />

                    </div>
                )}
                {selectedEditor === 'Layout' && (
                    <div className="mjwi-editor-layout">

                        <Accordion allowZeroExpanded={true}>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Icon
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>

                                    <Icon 
                                        widget = {widget}
                                        handleInputChange = {this.handleInputChange}
                                        toggleColorPicker = {toggleColorPicker}
                                        setColorsAndPreview = {this.setColorsAndPreview}
                                    />
                                
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Toolbox
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>

                                    <Toolbox 
                                        widget = {widget}
                                        handleInputChange = {this.handleInputChange}
                                        toggleColorPicker = {toggleColorPicker}
                                        setColorsAndPreview = {this.setColorsAndPreview}
                                    />
                                
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>
                        

                    </div>
                )}


            </div>

        );
    }
}

export default Accessibility;
