import React, { Component } from 'react';

import Accessibility from './widgets/Accessibility';
import ListingsForAppfolio from './widgets/listingsForAppfolio';
import Trustpilot from './widgets/Trustpilot';

import Button from '../Button';
import EditorLeftbar from './EditorLeftbar';
import handlePreviewHtml from './WidgetPreviewHandler';

import PurchasePopup from './PurchasePopup';
import InstallPopup from '../InstallPopup';

class WidgetWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedEditor: 'Builder',
            widget: {
                widgetType: '',
                id: '',
                title: '',
                htmlData: {},
                isPro: false,
            },
            previewHtml: '',
            fieldErrors: '',
            // verifyPassPopup: React.createRef(),
            purchasePopup: React.createRef(),
            installPopup: React.createRef(),
        };

    }

    handleLeftBarClick = (editor) => {
        this.setState({
            selectedEditor: editor,
        });
    };

    updateWrapperWidget = async(widgetForWrapper) => {
        this.setState((prevState) => ({
            ...prevState,
            widget: widgetForWrapper,
        }));
    }

    previewWidgetOnClick = async () => {
        const { widget } = this.state;
        this.previewWidget(widget);
    };

    previewWidget = async (widgetForPreview) => {

        this.setState({ previewHtml: '<div>Loading...</div>' });

        // previewWidget is being called from child widgets and now let's set that widget for this wrapper
        this.setState((prevState) => ({
            ...prevState,
            widget: widgetForPreview,
        }));
        
        if(widgetForPreview.widgetType){

            const data = await handlePreviewHtml(widgetForPreview);

            if(data.error){
                const { handleErrorMsgUpdate } = this.props;
                await handleErrorMsgUpdate(data.error);
            }
            
            if (data.previewHtml) {
                this.setState((prevState) => ({
                    ...prevState,
                    error: '',
                    previewHtml: data.previewHtml
                }));
            }

        }
        else{
            const { handleErrorMsgUpdate } = this.props;
            await handleErrorMsgUpdate('Validation Error. Please fill all required fields.');
        }
        
    }

    handleSaveHTML = async () => {
        const { handleSaveChanges } = this.props;
        const { widget } = this.state;

        const thisWidget = this.props.thisWidget;
        let { widgetType } = thisWidget;

        let paidWidget = false;
        if(widgetType.price != 0 && widgetType.price != undefined){
            paidWidget = true;
        }

        // Validate common required fields
        const { handleErrorMsgUpdate } = this.props;
        if(!widget.title){
            await handleErrorMsgUpdate('Please enter a title for this widget.');
            return;
        }

        // Validate widget based required fields
        let type = widgetType.name;
        let error = "";
        switch (type) {
            case "appfolio":
                if (!widget.htmlData.appfolio.appfolioUrl) {
                    error = "Please Enter - Appfolio Listings URL";
                }
                break;
            case "accessibility":
                if (!widget.htmlData.accessibility.domainUrl) {
                    error = "Please Enter your website URL";
                }
                break;
            case "trustpilot":
                if (!widget.htmlData.trustpilot.url) {
                    error = "Please Enter your Trustpilot Profile URL";
                }
                break;
            default:
                break;
        }
        if (error) {
            await handleErrorMsgUpdate(error, "");
            return;
        } else {
            handleErrorMsgUpdate("", "");
        }

        // Ask for purchase for paid widgets
        if(paidWidget) { // !widget.isPro &&
            
            if(widget.expires){
                const widgetExpires = new Date( widget.expires );
                const today = new Date();
                if(today > widgetExpires){
                    this.state.purchasePopup.current.openPopup(widget);
                    return;
                }
            }
            else{
                this.state.purchasePopup.current.openPopup(widget);
                return;
            }
            
        }

        // Save the widget & Preview
        await handleSaveChanges(widget);
        this.setState(
            (prevState) => {
                return ({
                    ...prevState,
                   // previewHtml: '',
                })
            },
            () => {
                this.previewWidget(widget);
            }
        );
    };

    render() {

        const { selectedEditor, widget, previewHtml, installPopup } = this.state;

        const thisWidget = this.props.thisWidget;

        let { widgetType } = thisWidget;

        let paidWidget = false;
        if(widgetType.price != 0 && widgetType.price != undefined){
            paidWidget = true;
        }

        return (
            
            <div className="mjwi-editor">
                <div className={'mjwi-error-main' + (this.state.error ? '' : ' mjwi-hidden')}>{this.state.error}</div>
                <EditorLeftbar tabs={['Builder', 'Layout']} activeTab = {selectedEditor} handleLeftBarClick={this.handleLeftBarClick} />
                <div className="mjwi-editor-main">

                    {widgetType.name === "appfolio" && (
                        <ListingsForAppfolio
                            widget = {thisWidget}
                            selectedEditor = {selectedEditor}
                            previewWidget = {this.previewWidget}
                            updateWrapperWidget = {this.updateWrapperWidget}
                        />
                    )}

                    {widgetType.name === "accessibility" && (
                        <Accessibility
                            widget = {thisWidget}
                            selectedEditor = {selectedEditor}
                            previewWidget = {this.previewWidget}
                            updateWrapperWidget = {this.updateWrapperWidget}
                        />
                    )}

                    {widgetType.name === "trustpilot" && (
                        <Trustpilot
                            widget = {thisWidget}
                            selectedEditor = {selectedEditor}
                            previewWidget = {this.previewWidget}
                        />
                    )}

                    <div className="mjwi-editor-bottom">
                        <Button name="Save" eventHandler={this.handleSaveHTML} />
                        {
                            (thisWidget.id) ? 
                            <Button name="Install" eventHandler={() => {installPopup.current.openPopup(thisWidget, widgetType.name)}} /> 
                            : 
                            <Button name="Preview" eventHandler={this.previewWidgetOnClick} />
                        }
                    </div>
                </div>
                <div className=" mjwi-editor-display">
                    <div
                        // data-widget={JSON.stringify({ widgetData: widget })}
                        className="mj-widget mode-preview"
                        id={"mjwi-" + widgetType.name + "-preview"}
                    >
                        <div className={'mj-' + widgetType.name}>

                            {previewHtml && (
                                <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
                            )}

                        </div>
                    </div>
                </div>
                
                {/* <VerifyPassPopup ref={this.state.verifyPassPopup} updateVerifyPassState={this.updateVerifyPassState} /> */}

                {paidWidget && (
                    <PurchasePopup ref={this.state.purchasePopup} />
                )}

                <InstallPopup ref={installPopup}/>

            </div>

        );
    }
}

export default WidgetWrapper;
