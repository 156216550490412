import React, { Component } from 'react';

import getCheckboxField from '../../fields/checkbox';
import getSelectField from '../../fields/select';
import getRadioField from '../../fields/radio';

import getFonts from '../../../Functions/config/fonts';
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";

class Content extends Component {

    constructor(props) {
        super(props);
    }

    setColorsAndPreview = async (type, color) => {
        const { setColorsAndPreview } = this.props;
        await setColorsAndPreview('layout', type, color);
    }

    setSectionBg = async (color) => {
        await this.setColorsAndPreview('layoutSectionBg', color);
    }
    setrentColor = async (color) => {
        await this.setColorsAndPreview('layoutRentColor', color);
    }
    setrentBgColor = async (color) => {
        await this.setColorsAndPreview('layoutRentBg', color);
    }
    setDateColor = async (color) => {
        await this.setColorsAndPreview('layoutDateColor', color);
    }
    setDateBgColor = async (color) => {
        await this.setColorsAndPreview('layoutDateBg', color);
    }
    setTitleColor = async (color) => {
        await this.setColorsAndPreview('layoutTitleColor', color);
    }
    setAddressColor = async (color) => {
        await this.setColorsAndPreview('layoutAddressColor', color);
    }
    setBedColor = async (color) => {
        await this.setColorsAndPreview('layoutBedColor', color);
    }
    setBedIconColor = async (color) => {
        await this.setColorsAndPreview('layoutBedIconColor', color);
    }
    setBathColor = async (color) => {
        await this.setColorsAndPreview('layoutBathColor', color);
    }

    setBathIconColor = async (color) => {
        await this.setColorsAndPreview('layoutBathIconColor', color);
    }
    setdetailBtnColor = async (color) => {
        await this.setColorsAndPreview('layoutDetailColor', color);
    }
    setdetailBtnBgColor = async (color) => {
        await this.setColorsAndPreview('layoutDetailBg', color);
    }
    setapplyBtnColor = async (color) => {
        await this.setColorsAndPreview('layoutApplyColor', color);
    }
    setapplyBtnBgColor = async (color) => {
        await this.setColorsAndPreview('layoutApplyBg', color);
    }

    render(){

        const { widget, handleInputChange, toggleColorPicker } = this.props;
        const { appfolio } = widget.htmlData;
        const fontOptions = getFonts();

        return(

            <div className="mjwi-acc-panel-wrapper">

                <div className='mjwi-row'>    
                    <div className='mjwi-col33'>
                        <label>
                            Layout
                        </label>                                    
                    </div>
                    <div className='mjwi-col66'>

                        <div className='mjwi-row mjwi-flex-end'>
                            <div className='mjwi-col25 mjwi-radio-svg'>
                                <label>
                                    {getRadioField('layoutTemplate', 'eagle', appfolio.layout.layoutTemplate, handleInputChange)}
                                    <svg width="50px" height="50px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 9H21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </label>
                            </div>
                            <div className='mjwi-col25 mjwi-radio-svg'>
                                <label>
                                    {getRadioField('layoutTemplate', 'hawk', appfolio.layout.layoutTemplate, handleInputChange)}
                                    <svg width="50px" height="50px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 3V21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='mjwi-row'>    
                    <div className='mjwi-col33'>
                            <label>
                                Font Family
                            </label>                                    
                        </div>
                        <div className='mjwi-col66'>
                            {getSelectField('layoutFontFamily', fontOptions, appfolio.layout.layoutFontFamily, handleInputChange)}
                        </div>
                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col50'>
                        <label>Columns</label>
                    </div>
                    <div className='mjwi-col50'>
                        <select
                            id="appfolioCol"
                            name="appfolioCol"
                            value={appfolio.appfolioCol}
                            onChange={handleInputChange}
                        >
                            <option value="1">1 column</option>
                            <option value="2">2 columns</option>
                            <option value="3">3 columns</option>
                            <option value="4">4 columns</option>
                        </select>
                    </div>
                </div>

                <div className="mjwi-apfl-display-card-items">

                    <div className='mjwi-row'>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('layoutRent', appfolio.layout.layoutRent, handleInputChange)}
                                Rent
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('layoutDate', appfolio.layout.layoutDate, handleInputChange)}
                                Date
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('layoutTitle', appfolio.layout.layoutTitle, handleInputChange)}
                                Title
                            </label>
                        </div>
                    </div>

                    <div className='mjwi-row'>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('layoutAddress', appfolio.layout.layoutAddress, handleInputChange)}
                                Address
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('layoutBeds', appfolio.layout.layoutBeds, handleInputChange)}
                                Beds
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('layoutBaths', appfolio.layout.layoutBaths, handleInputChange)}
                                Baths
                            </label>
                        </div>
                    </div>

                    <div className='mjwi-row'>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('layoutDetail', appfolio.layout.layoutDetail, handleInputChange)}
                                Details
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            <label>
                                {getCheckboxField('layoutApply', appfolio.layout.layoutApply, handleInputChange)}
                                Apply
                            </label>
                        </div>
                        <div className='mjwi-col33'>
                            
                        </div>
                    </div>

                </div>

                <div className='mjwi-row'>
                    <div className='mjwi-col50'>
                        <label>Section Background</label>
                    </div>
                    <div className='mjwi-col50 mjwi-clr-picker'>
                        
                        <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutSectionBg }}></span>
                        <HexAlphaColorPicker color={appfolio.layout.layoutSectionBg} onChange={this.setSectionBg} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                        <HexColorInput color={appfolio.layout.layoutSectionBg} onChange={this.setSectionBg} />

                    </div>
                </div>

                {
                    appfolio.layout.layoutRent === 'on' && (
                        
                        <>
                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Rent Color</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutRentColor }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutRentColor} onChange={this.setrentColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutRentColor} onChange={this.setrentColor} />

                            </div>
                        </div>

                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Rent Background</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutRentBg }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutRentBg} onChange={this.setrentBgColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutRentBg} onChange={this.setrentBgColor} />

                            </div>
                        </div>
                        </>

                    )
                }

                {
                    appfolio.layout.layoutDate === 'on' && (
                        
                        <>
                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Date Color</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutDateColor }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutDateColor} onChange={this.setDateColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutDateColor} onChange={this.setDateColor} />

                            </div>
                        </div>

                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Date Background</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutDateBg }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutDateBg} onChange={this.setDateBgColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutDateBg} onChange={this.setDateBgColor} />

                            </div>
                        </div>
                        </>

                    )
                }

                {
                    appfolio.layout.layoutTitle === 'on' && (
                        
                        <div className='mjwi-row'>
                            <div className='mjwi-col50 mjwi-row'>
                                <div className='mjwi-col50'>
                                    <label>Title</label>
                                    &nbsp;
                                </div>
                                <div className='mjwi-col50 mjwi-row'>
                                    <div className='mjwi-col90 mjwi-row'>
                                        <input
                                            type="number"
                                            name="layoutTitleSize"
                                            value={appfolio.layout.layoutTitleSize}
                                            onChange={handleInputChange}
                                        />&nbsp;px
                                    </div>
                                    <div className='mjwi-col10'>
                                    </div>
                                </div>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutTitleColor }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutTitleColor} onChange={this.setTitleColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutTitleColor} onChange={this.setTitleColor} />

                            </div>
                        </div>

                    )
                }

                {
                    appfolio.layout.layoutAddress === 'on' && (
                        
                        <div className='mjwi-row'>
                            <div className='mjwi-col50 mjwi-row'>
                                <div className='mjwi-col50'>
                                    <label>Address</label>
                                    &nbsp;
                                </div>
                                <div className='mjwi-col50 mjwi-row'>
                                    <div className='mjwi-col90 mjwi-row'>
                                        <input
                                            type="number"
                                            name="layoutAddressSize"
                                            value={appfolio.layout.layoutAddressSize}
                                            onChange={handleInputChange}
                                        />&nbsp;px
                                    </div>
                                    <div className='mjwi-col10 mjwi-row'>
                                    </div>
                                </div>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutAddressColor }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutAddressColor} onChange={this.setAddressColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutAddressColor} onChange={this.setAddressColor} />

                            </div>
                        </div>

                    )
                }

                {
                    appfolio.layout.layoutBeds === 'on' && (
                        <>
                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Bed Icon Color</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutBedIconColor }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutBedIconColor} onChange={this.setBedIconColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutBedIconColor} onChange={this.setBedIconColor} />
                            </div>
                        </div>
                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Bed Text Color</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutBedColor }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutBedColor} onChange={this.setBedColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutBedColor} onChange={this.setBedColor} />
                            </div>
                        </div>
                        </>
                    )
                }

                {
                    appfolio.layout.layoutBaths === 'on' && (
                        <>
                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Bath Icon Color</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutBathIconColor }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutBathIconColor} onChange={this.setBathIconColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutBathIconColor} onChange={this.setBathIconColor} />
                            </div>
                        </div>
                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Bath Text Color</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutBathColor }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutBathColor} onChange={this.setBathColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutBathColor} onChange={this.setBathColor} />
                            </div>
                        </div>
                        </>

                    )
                }

                {
                    appfolio.layout.layoutDetail === 'on' && (
                        <>
                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Details Btn Color</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutDetailColor }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutDetailColor} onChange={this.setdetailBtnColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutDetailColor} onChange={this.setdetailBtnColor} />
                            </div>
                        </div>
                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Details Btn BG</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutDetailBg }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutDetailBg} onChange={this.setdetailBtnBgColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutDetailBg} onChange={this.setdetailBtnBgColor} />
                            </div>
                        </div>
                        </>
                    )
                }

                {
                    appfolio.layout.layoutApply === 'on' && (
                        <>
                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Apply Btn Color</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutApplyColor }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutApplyColor} onChange={this.setapplyBtnColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutApplyColor} onChange={this.setapplyBtnColor} />
                            </div>
                        </div>
                        <div className='mjwi-row'>
                            <div className='mjwi-col50'>
                                <label>Apply Btn BG</label>
                            </div>
                            <div className='mjwi-col50 mjwi-clr-picker'>
                                <span className="clrPickerVal" onClick={toggleColorPicker} style={{ backgroundColor: appfolio.layout.layoutApplyBg }}></span>
                                <HexAlphaColorPicker color={appfolio.layout.layoutApplyBg} onChange={this.setapplyBtnBgColor} style={{display: "none"}} className="mjwi-apfl-color-picker" />
                                <HexColorInput color={appfolio.layout.layoutApplyBg} onChange={this.setapplyBtnBgColor} />
                            </div>
                        </div>

                        <div className='mjwi-row'>
                            <div className='mjwi-col33'>
                                <label>Apply Link</label>
                            </div>
                            <div className='mjwi-col66'>
                                <input
                                    type="text"
                                    placeholder="Leave blank for default link."
                                    name="layoutApplyLink"
                                    value={appfolio.layout.layoutApplyLink}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        </>
                    )
                }

            </div>

        );

    }

}

export default Content;