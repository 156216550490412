const getFonts = () => {

    const googleFonts = [
        'Cabin',
        'Dancing Script',
        'Lato',
        'Montserrat',
        'Noto Sans',
        'Open Sans',
        'Poppins',
        'Quicksand',
        'Roboto',
        'Roboto Condensed',
        'Ubuntu',
    ];

    return googleFonts;

}

export default getFonts;
