import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';

import InfoPopup from '../InfoPopup';

import Header from "./appfolio/header";
import Content from "./appfolio/content";
import Detail from "./appfolio/detail";

import toggleColorPicker from '../../Functions/toggleColorPicker';

class ListingsForAppfolio extends Component {
    constructor(props) {
        super(props);

        const { widget } = props;
        const { htmlData } = widget;
        
        this.state = {
            widget: {
                htmlData: {
                    appfolio: {
                        appfolioUrl: decodeURIComponent(htmlData?.appfolio?.appfolioUrl || 'https://dkmgmt.appfolio.com/listings'),
                        header: {
                            appfolioHeading: htmlData?.appfolio?.header?.appfolioHeading || 'Find Your Dream Home',
                            headercolor: htmlData?.appfolio?.header?.headercolor || '#ffffff',
                            headerbg: htmlData?.appfolio?.header?.headerbg || '#232532',
                            headerFontFamily: htmlData?.appfolio?.header?.headerFontFamily || 'Montserrat',
                            headerFontSize: htmlData?.appfolio?.header?.headerFontSize || '40',
                            headerFontBold: htmlData?.appfolio?.header?.headerFontBold || 'off',
                            headerFontItalic: htmlData?.appfolio?.header?.headerFontItalic || 'off',
                        },
                        layout: {
                            layoutTemplate: htmlData?.appfolio?.layout?.layoutTemplate || 'eagle',
                            layoutFontFamily: htmlData?.appfolio?.layout?.layoutFontFamily || 'Montserrat',
                            layoutRent: htmlData?.appfolio?.layout?.layoutRent || 'on',
                            layoutDate: htmlData?.appfolio?.layout?.layoutDate || 'on',
                            layoutTitle: htmlData?.appfolio?.layout?.layoutTitle || 'on',
                            layoutAddress: htmlData?.appfolio?.layout?.layoutAddress || 'on',
                            layoutBeds: htmlData?.appfolio?.layout?.layoutBeds || 'on',
                            layoutBaths: htmlData?.appfolio?.layout?.layoutBaths || 'on',
                            layoutDetail: htmlData?.appfolio?.layout?.layoutDetail || 'on',
                            layoutApply: htmlData?.appfolio?.layout?.layoutApply || 'on',
                            layoutSectionBg: htmlData?.appfolio?.layout?.layoutSectionBg || '#e7f2ff',
                            layoutRentColor: htmlData?.appfolio?.layout?.layoutRentColor || '#ffffff',
                            layoutRentBg: htmlData?.appfolio?.layout?.layoutRentBg || '#598fcd',
                            layoutDateColor: htmlData?.appfolio?.layout?.layoutDateColor || '#ffffff',
                            layoutDateBg: htmlData?.appfolio?.layout?.layoutDateBg || '#598fcd',
                            layoutTitleSize: htmlData?.appfolio?.layout?.layoutTitleSize || '18',
                            layoutTitleColor: htmlData?.appfolio?.layout?.layoutTitleColor || '#000000',
                            layoutAddressSize: htmlData?.appfolio?.layout?.layoutAddressSize || '14',
                            layoutAddressColor: htmlData?.appfolio?.layout?.layoutAddressColor || '#000000',
                            layoutBedColor: htmlData?.appfolio?.layout?.layoutBedColor || '#000000',
                            layoutBedIconColor: htmlData?.appfolio?.layout?.layoutBedIconColor || '#000000',
                            layoutBathColor: htmlData?.appfolio?.layout?.layoutBathColor || '#000000',
                            layoutBathIconColor: htmlData?.appfolio?.layout?.layoutBathIconColor || '#000000',
                            layoutDetailColor: htmlData?.appfolio?.layout?.layoutDetailColor || '#ffffff',
                            layoutDetailBg: htmlData?.appfolio?.layout?.layoutDetailBg || '#27547c',
                            layoutApplyColor: htmlData?.appfolio?.layout?.layoutApplyColor || '#ffffff',
                            layoutApplyBg: htmlData?.appfolio?.layout?.layoutApplyBg || '#444444',
                            layoutApplyLink: htmlData?.appfolio?.layout?.layoutApplyLink || '',
                        },
                        appfolioDisplayFilters: {
                            search: htmlData.appfolio?.appfolioDisplayFilters?.search || 'on',
                            cats: htmlData.appfolio?.appfolioDisplayFilters?.cats || 'off',
                            dogs: htmlData.appfolio?.appfolioDisplayFilters?.dogs || 'off',
                            minRent: htmlData.appfolio?.appfolioDisplayFilters?.minRent || 'on',
                            maxRent: htmlData.appfolio?.appfolioDisplayFilters?.maxRent || 'on',
                            beds: htmlData.appfolio?.appfolioDisplayFilters?.beds || 'on',
                            baths: htmlData.appfolio?.appfolioDisplayFilters?.baths || 'on',
                            cities: htmlData.appfolio?.appfolioDisplayFilters?.cities || 'on',
                            zip: htmlData.appfolio?.appfolioDisplayFilters?.zip || 'on',
                            desiredMoveIn: htmlData.appfolio?.appfolioDisplayFilters?.desiredMoveIn || 'off',
                            sorting: htmlData.appfolio?.appfolioDisplayFilters?.sorting || 'on',
                        },
                        detail: {
                            detailOpenSeparate: htmlData?.appfolio?.detail?.detailOpenSeparate || 'off',

                            detailTemplate: htmlData?.appfolio?.detail?.detailTemplate || 'half',
                            detailSectionBg: htmlData?.appfolio?.detail?.detailSectionBg || '#ffffff',
                            detailSectionTextColor: htmlData?.appfolio?.detail?.detailSectionTextColor || '#000000',
                            detailSectionFF: htmlData?.appfolio?.detail?.detailSectionFF || 'Montserrat',

                            detailAddress: htmlData?.appfolio?.detail?.detailAddress || 'on',
                            detailPrice: htmlData?.appfolio?.detail?.detailPrice || 'on',
                            detailAvailable: htmlData?.appfolio?.detail?.detailAvailable || 'on',
                            detailPhone: htmlData?.appfolio?.detail?.detailPhone || 'on',
                            detailShowingBtn: htmlData?.appfolio?.detail?.detailShowingBtn || 'on',
                            detailApplyBtn: htmlData?.appfolio?.detail?.detailApplyBtn || 'on',
                            detailContactBtn: htmlData?.appfolio?.detail?.detailContactBtn || 'on',

                            detailAddressFontFamily: htmlData?.appfolio?.detail?.detailAddressFontFamily || 'Montserrat',
                            detailAddressFontSize: htmlData?.appfolio?.detail?.detailAddressFontSize || '22',
                            detailAddressFontBold: htmlData?.appfolio?.detail?.detailAddressFontBold || 'on',
                            detailAddressColor: htmlData?.appfolio?.detail?.detailAddressColor || '#000000',

                            detailPriceColor: htmlData?.appfolio?.detail?.detailPriceColor || '#000000',
                            detailPriceFontFamily: htmlData?.appfolio?.detail?.detailPriceFontFamily || 'Montserrat',
                            detailPriceFontSize: htmlData?.appfolio?.detail?.detailPriceFontSize || '28',
                            detailPriceFontBold: htmlData?.appfolio?.detail?.detailPriceFontBold || 'on',
                            
                            detailAvailableColor: htmlData?.appfolio?.detail?.detailAvailableColor || '#000000',
                            detailAvailableFontFamily: htmlData?.appfolio?.detail?.detailAvailableFontFamily || 'Montserrat',
                            detailAvailableFontSize: htmlData?.appfolio?.detail?.detailAvailableFontSize || '28',
                            detailAvailableFontBold: htmlData?.appfolio?.detail?.detailAvailableFontBold || 'on',
                            
                            detailPhoneColor: htmlData?.appfolio?.detail?.detailPhoneColor || '#000000',
                            detailPhoneFontFamily: htmlData?.appfolio?.detail?.detailPhoneFontFamily || 'Montserrat',
                            detailPhoneFontSize: htmlData?.appfolio?.detail?.detailPhoneFontSize || '18',
                            detailPhoneFontBold: htmlData?.appfolio?.detail?.detailPhoneFontBold || 'on',

                            detailShowingColor: htmlData?.appfolio?.detail?.detailShowingColor || '#ffffff',
                            detailShowingBg: htmlData?.appfolio?.detail?.detailShowingBg || '#598fcd',
                            detailShowingFontFamily: htmlData?.appfolio?.detail?.detailShowingFontFamily || 'Montserrat',
                            detailShowingFontSize: htmlData?.appfolio?.detail?.detailShowingFontSize || '16',
                            detailShowingFontBold: htmlData?.appfolio?.detail?.detailShowingFontBold || 'off',
                            
                            detailApplyColor: htmlData?.appfolio?.detail?.detailApplyColor || '#ffffff',
                            detailApplyBg: htmlData?.appfolio?.detail?.detailApplyBg || '#598fcd',
                            detailApplyFontFamily: htmlData?.appfolio?.detail?.detailApplyFontFamily || 'Montserrat',
                            detailApplyFontSize: htmlData?.appfolio?.detail?.detailApplyFontSize || '16',
                            detailApplyFontBold: htmlData?.appfolio?.detail?.detailApplyFontBold || 'off',
                            
                            detailContactColor: htmlData?.appfolio?.detail?.detailContactColor || '#ffffff',
                            detailContactBg: htmlData?.appfolio?.detail?.detailContactBg || '#598fcd',
                            detailContactFontFamily: htmlData?.appfolio?.detail?.detailContactFontFamily || 'Montserrat',
                            detailContactFontSize: htmlData?.appfolio?.detail?.detailContactFontSize || '16',
                            detailContactFontBold: htmlData?.appfolio?.detail?.detailContactFontBold || 'off',
                            
                        },
                        appfolioSortOrder: htmlData?.appfolio?.appfolioSortOrder || 'date_posted',
                        appfolioCol: htmlData?.appfolio?.appfolioCol || '3',
                        clientGmapApi: htmlData?.appfolio?.clientGmapApi || '',
                        group: htmlData?.appfolio?.group || '',
                    }
                },
                widgetType: widget.widgetType.id,
                widgetTypeName: widget.widgetType.name,
                title: widget.title ? widget.title : '',
                expires: widget.expires ? widget.expires : '',
                isUrlChanged: true,
            },
            previewHtml: '',
            fieldErrors: "",
            infoPopup: React.createRef()
        };

    }

    handleInputChange = async (e) => {
        
        const { type, name, value, checked } = e.target;

        if(name === 'appfolioUrl' || name === 'clientGmapApi' || name === 'group'){
            this.setState((prevState) => ({
                widget: {
                    ...prevState.widget,
                    isUrlChanged: true
                },
            }));
        }
        else{
            this.setState((prevState) => ({
                widget: {
                    ...prevState.widget,
                    isUrlChanged: false
                },
            }));
        }

        await this.setState((prevState) => ({
            widget: {
                ...prevState.widget,
                [name]: value,
                htmlData: {
                    ...prevState.widget.htmlData,
                    appfolio: {
                        ...prevState.widget.htmlData.appfolio,
                        [name]: value,
                        header: {
                            ...prevState.widget.htmlData.appfolio.header,
                            [name]: (type == 'checkbox' ? (checked ? 'on' : 'off'): value),
                        },
                        layout: {
                            ...prevState.widget.htmlData.appfolio.layout,
                            [name]: (type == 'checkbox' ? (checked ? 'on' : 'off'): value),
                        },
                        appfolioDisplayFilters: {
                            ...prevState.widget.htmlData.appfolio.appfolioDisplayFilters,
                            [name]: (checked ? 'on' : 'off'),
                        },
                        detail: {
                            ...prevState.widget.htmlData.appfolio.detail,
                            [name]: (type == 'checkbox' ? (checked ? 'on' : 'off'): value),
                        },
                    },
                },
            },
        }));

        // Handling Sort Order here because of triggering dropdown change event
        if(name == 'appfolioSortOrder'){
            document.querySelector("#filterSort").value = value;
            var event = new Event('change');
            document.querySelector("#filterSort").dispatchEvent(event);
        }

        // display preview if url is entered
        let apflURL = this.state.widget.htmlData.appfolio.appfolioUrl;
        if(apflURL){
            const { previewWidget } = this.props;
            await previewWidget(this.state.widget);
        }
        // otherwise set state of widget wrapper
        else{
            const { updateWrapperWidget } = this.props;
            await updateWrapperWidget(this.state.widget);
        }

    };

    async componentDidMount() {
        let apflURL = this.state.widget.htmlData.appfolio.appfolioUrl;
        if(apflURL){
            const { previewWidget } = this.props;
            await previewWidget(this.state.widget);
        }
    }

    // common function for color pickers
    setColorsAndPreview = async (section, stateItem, value) => {
        this.setState((prevState) => ({
            widget: {
                ...prevState.widget,
                isUrlChanged: false,
                htmlData: {
                    ...prevState.widget.htmlData,
                    appfolio: {
                        ...prevState.widget.htmlData.appfolio,
                        [section]:{
                            ...prevState.widget.htmlData.appfolio[section],
                            [stateItem] : value,
                        }
                    },
                },
            },
        }));
        let apflURL = this.state.widget.htmlData.appfolio.appfolioUrl;
        if(apflURL){
            const { previewWidget } = this.props;
            // color is being changed from picker and input field. state update is being updated with previous color value. So, let's prepare widget with latest value.
            const widgetTosend = this.state.widget;
            widgetTosend.htmlData.appfolio[section][stateItem] = value;
            widgetTosend.isUrlChanged = false;
            await previewWidget(widgetTosend); // this.state.widget
        }
    }

    render() {

        const { widget } = this.state;

        const { selectedEditor } = this.props;

        const { title } = widget
        const { appfolio } = widget.htmlData

        const gApiSteps = `
        <h2>Step-by-Step Guide to Add Your Domain to Allowed Referrers:</h2>
        <ol>
        <li>Go to the Google Cloud Console: Navigate to the Google Cloud Console.
        <li>Select Your Project: Make sure you have the correct project selected from the project dropdown menu at the top of the page.
        <li>Access API & Services: Click on the "Navigation Menu" (three horizontal lines in the top-left corner), then select "APIs & Services" > "Credentials."
        <li>Identify Your API Key: In the "Credentials" page, you will see a list of API keys. Find the API key you are using for the Maps JavaScript API.
        <li>Edit API Key: Click on the name of your API key to edit its settings.
        <li>Application Restrictions: Scroll down to the "Application restrictions" section. Select "HTTP referrers (websites)" to restrict the usage of the API key to specific websites.
        <li>Add Your Domain: Under "Website restrictions," click "Add an item" and enter your domain in the format specified (e.g., example.com/* for a broad match or https://www.example.com/specificpage for a more specific path).
        <li>Note: Ensure you include all variations of your domain that your application might use, including with or without www, different subdomains, or any specific paths, if applicable.
        <li>Save Changes: After adding your domain(s), click the "Save" button at the bottom of the page to apply your changes.
        </ol>
        `;

        return (

            <div className="mjwi-editor-main-inner">

                {selectedEditor === 'Builder' && (
                    <div className="mjwi-editor-builder">

                        <label htmlFor="title">Widget Name</label>
                            <input
                                    type="text"
                                    name="title"
                                    value={title}
                                    placeholder="Title"
                                    onChange={this.handleInputChange}
                                />

                            <label htmlFor="appfolioUrl">Appfolio Listings URL</label>
                            <input
                                type="text"
                                name="appfolioUrl"
                                value={appfolio.appfolioUrl}
                                placeholder="https://dkmgmt.appfolio.com/listings"
                                onChange={this.handleInputChange}
                            />
                            <label htmlFor="clientGmapApi">Google Map API Key <i onClick={() => this.state.infoPopup.current.openPopup()} className="fa-solid fa-circle-info"></i></label>
                            <input
                                type="text"
                                name="clientGmapApi"
                                value={appfolio.clientGmapApi}
                                onChange={this.handleInputChange}
                            />

                            <label>Group</label>
                            <input
                                type="text"
                                name="group"
                                value={appfolio.group}
                                onChange={this.handleInputChange}
                            />

                            
                            <InfoPopup ref={this.state.infoPopup} info={gApiSteps} />

                    </div>
                )}

                {selectedEditor === 'Layout' && (
                    <div className="mjwi-editor-layout">


                    <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Top Banner
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>

                                <Header 
                                    widget = {widget}
                                    handleInputChange = {this.handleInputChange}
                                    toggleColorPicker = {toggleColorPicker}
                                    setColorsAndPreview = {this.setColorsAndPreview}
                                />
                             
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    All Listings Page
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>

                                <Content 
                                    widget = {widget}
                                    handleInputChange = {this.handleInputChange}
                                    toggleColorPicker = {toggleColorPicker}
                                    setColorsAndPreview = {this.setColorsAndPreview}
                                />

                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Single Listing Page
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>

                                <Detail 
                                    widget = {widget}
                                    handleInputChange = {this.handleInputChange}
                                    toggleColorPicker = {toggleColorPicker}
                                    setColorsAndPreview = {this.setColorsAndPreview}
                                />
                                
                            </AccordionItemPanel>
                        </AccordionItem>


                    </Accordion>

                        

                        

                    </div>
                )}

            </div>
        );
    }
}

export default ListingsForAppfolio;
