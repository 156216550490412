import React, { Component } from 'react';
import Button from '../../Button';
import apiInstance from '../../apiInstance';

class DeletePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.data?.userId || '',
            availableWidgetId: props.data?.availableWidgetId || '',
            widgetId: props.data?.widgetId || '',
            type: props?.type || 'user'
        };
    }

    delete = () => {
        let {type} = this.state;

        if(type === 'user') {
            
            const { userId } = this.state;
            apiInstance.delete(`/devadmin/user/${userId}`)
                .then(response => {
                    this.props.deleteUser(userId)
                    this.props.closePopup();
                })
                .catch(error => {
                    console.error('Error deleting the user:', error);
                    this.props.updateError(error.response.data)
                });
        } else if(type === 'availableWidget') {
            const { availableWidgetId } = this.state;
            apiInstance.delete(`/devadmin/availableWidget/${availableWidgetId}`)
                .then(response => {
                    this.props.deleteAvailableWidget(availableWidgetId)
                    this.props.closePopup();
                })
                .catch(error => {
                    console.error('Error deleting the widget:', error);
                    this.props.updateError(error.response.data)
                });
        } else if(type === 'widget') {
            const { widgetId } = this.state;
            apiInstance.delete(`/devadmin/widget/${widgetId}`)
                .then(response => {
                    this.props.deleteWidget(widgetId)
                    this.props.closePopup();
                })
                .catch(error => {
                    console.error('Error deleting the widget:', error);
                    this.props.updateError(error.response.data)
                });
        }

        
    }

    render () {

        return (
            
                <div className='mjwidev-delete-user-pp mjwidev-pp'>
                    <h2>DELETE</h2>
                    <p>Do you really want to delete this {this.state.type == "user" ? "user" : "widget"}?</p>
                    <div className="mjwi-ct-card-btns">
                        <Button name="Delete" eventHandler={() => {this.delete()}} />
                    </div>
                </div>
            
        );
    }
}

export default DeletePopup;
  