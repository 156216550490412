const getCheckboxField = (name, checked, onchange) => {

    let checkedAttr = false;
    if(checked == 'on'){
        checkedAttr = true;
    }
    
    return <input type="checkbox" name={name} checked={checkedAttr} onChange={onchange} />;

}

export default getCheckboxField;
