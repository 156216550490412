import axios from 'axios';

const widgetPreview = async (widget) => {

    window.widgetJson = widget;

    const scriptId = widget.name;
    const mainScript = widget.script.main;
    const libScript = widget.script.lib;

    window.mjFrontend = process.env.REACT_APP_CLIENT_URL;

    if(libScript){
        const existingLibScript = document.getElementById('mjwi-script-' + scriptId + '-lib');
        if (existingLibScript) {
            existingLibScript.parentNode.removeChild(existingLibScript);
        }

        // check if lib file exists and append it
        const widgetLibScript = document.createElement('script');
        widgetLibScript.src = process.env.REACT_APP_CLIENT_URL + '/scripts/lib/' + libScript + '.js';
        try {
            const response = await axios.head(widgetLibScript.src, { timeout: 5000 });
            if(response.status === 200){
                widgetLibScript.type = 'text/javascript';
                widgetLibScript.id = 'mjwi-script-' + scriptId + '-lib';
                document.head.appendChild(widgetLibScript);
            }
        }
        catch{
            console.log('Lib file does not exist');
        }
    }

    if(mainScript){

        // main file for the widget
        const existingScript = document.getElementById('mjwi-script-' + scriptId);
        if (existingScript) {
            existingScript.parentNode.removeChild(existingScript);
        }

        if (scriptId) {
            const scriptElement = document.createElement('script');
            scriptElement.src = process.env.REACT_APP_CLIENT_URL + '/scripts/widget/' + scriptId + '.js';
            try {
                const response = await axios.head(scriptElement.src, { timeout: 5000 });
                if(response.status === 200){
                    scriptElement.type = 'text/javascript';
                    scriptElement.id = 'mjwi-script-' + scriptId;
                    document.head.appendChild(scriptElement);
                }
            }
            catch{
                console.log('Main file does not exist');
            }
        }

    }


}

export default widgetPreview;
