import React, { Component } from 'react';
import Button from '../../Button';
import apiInstance from '../../apiInstance';

class AddUserPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                id: props.data?.user?.id || '',
                displayName: props.data?.user?.displayName || '',
                email: props.data?.user?.email || '',
                userRole: props.data?.user?.userRole || '',
                password: props.data?.user?.password || ''
            },
            type: props?.type || 'add',
            fieldErrors: {},
        };
    }

    // openPopup = (userId ='', user = {}) => {
    //     if(userId !== '') {

    //         this.setState({
    //             user: user,
    //             type: 'update',
    //             displayPopup: true,
    //             fieldErrors: {},
    //             error: ''
    //         });

    //     } else {
    //         this.setState({
    //             user: {
    //                 displayName: '',
    //                 email: '',
    //                 userRole: '',
    //                 password: ''
    //             },
    //             type: 'add',
    //             displayPopup: true,
    //             fieldErrors: {},
    //             error: ''
    //         });
    //     }
    //     this.props.changeOverlayStatus('true');
    // }

    // closePopup = () => {
    //     this.setState({
    //         user: {
    //             displayName: '',
    //             email: '',
    //             userRole: '',
    //             password: ''
    //         },
    //         displayPopup: false,
    //         fieldErrors: {},
    //         error: ''
    //     });
    //     this.props.changeOverlayStatus('false');
    // }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState((prevState) => ({
            user: {
                ...prevState.user,
                [name]: value
            }
        }));
    }

    addUser = () => {
        let {user, type} = this.state;
        if(type == 'update') {

            apiInstance.put(`/devadmin/user/${user.id}`, user)
            .then((response) => {
                this.props.updateUser(response.data.user)
                this.props.closePopup();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 400 && error.response.data.fieldErrors) {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                fieldErrors: error.response.data.fieldErrors
                            })
                        })
                    }
                    else {
                        this.props.updateError(error.response.data);
                    }
                }
            });

        } else {
            
            apiInstance.post(`/devadmin/user`, user)
            .then((response) => {
                this.props.addUser(response.data.user)
                this.props.closePopup();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 400 && error.response.data.fieldErrors) {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                fieldErrors: error.response.data.fieldErrors
                            })
                        })
                    }
                    else {
                        this.props.updateError(error.response.data);
                    }
                }
            });
        }
    }

    render() {
        const { type, fieldErrors } = this.state;
        const { displayName, email, userRole, password } = this.state.user;

        return (
                <div className='mjwidev-add-user-pp mjwidev-pp'>
                    <h2>{type === 'add' ? 'ADD USER' : 'UPDATE USER'}</h2>
                    <form type='#'>
                        <div className='mjwidev-input-field'>
                            <input
                                type="text"
                                placeholder="Display name"
                                name="displayName"
                                value={displayName}
                                onChange={this.handleInputChange}
                            />
                             {fieldErrors.displayName && <span className="mjwi-error">{fieldErrors.displayName}</span>}
                        </div>

                        <div className='mjwidev-input-field'>
                            <input
                                type="email"
                                placeholder="Email Address"
                                name="email"
                                value={email}
                                onChange={this.handleInputChange}
                            />
                            {fieldErrors.email && <span className="mjwi-error">{fieldErrors.email}</span>}
                        </div>

                        <div className='mjwidev-input-field'>
                            <select
                                name="userRole"
                                value={userRole}
                                onChange={this.handleInputChange}
                            >
                                <option value={""}>Select</option>
                                <option value={"admin"}>Admin</option>
                            </select>
                            {fieldErrors.userRole && <span className="mjwi-error">{fieldErrors.userRole}</span>}
                        </div>

                        <div className='mjwidev-input-field'>
                            <input
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={password}
                                onChange={this.handleInputChange}
                            />
                            {fieldErrors.password && <span className="mjwi-error">{fieldErrors.password}</span>}
                        </div>
                        
                    </form>
                    <Button name={type === 'add' ? 'Add' : 'Update'} eventHandler={this.addUser} />
                </div>
        );
    }
}

export default AddUserPopup;
