const toggleColorPicker = (e) => {
    const picker = e.target.nextElementSibling;

    const allColorPicker = document.querySelectorAll('.react-colorful');
    for (let index = 0; index < allColorPicker.length; index++) {
        if(picker != allColorPicker[index]){
            allColorPicker[index].style.display = "none";
        }
    }

    if (picker.style.display === "none") {
        var top;
        if((e.pageY+200)<(window.innerHeight + window.scrollY)){
            top = 35;
        }
        else{
            top = -205;
        }
        picker.style.top = top + 'px';

        picker.style.display = "flex";
    } 
    else {
        picker.style.display = "none";
    }
}

export default toggleColorPicker;