import apiInstance from "../../apiInstance";
import widgetPreview from '../widgetPreview.js';

const trustPilotPreview = async (widget) => {

    if(widget.isUrlChanged) {

        var data = {
            error: '',
            fieldErrors: '',
            serveData: {},
            previewHtml: ''
        }
        var request_body = { widget };
    
        return apiInstance.post('/widget/preview/', request_body)
        .then(response => {
            let resData = response.data.data;
            
            // data.serveData = resData.serveData;
            
            const dataNeeded = {
                name: widget.widgetTypeName,
                title: widget.title,
                htmlData: widget.htmlData,
                script: widget.widgetTypeScripts,
                content: resData.serveData,
                isUrlChanged: widget.isUrlChanged
            }
    
            widgetPreview(dataNeeded);
    
            return data;
        })
        .catch(error => {
            console.log(error)
            data.error = error;
            return data;
        });

    } else {

        const trustpilotData = widget.htmlData.trustpilot;
        const reviewFilter = trustpilotData.reviewFilter;

        // update the css variables
        var cssRoot = document.querySelector(':root');
        cssRoot.style.setProperty('--trustpilot-logo-display', trustpilotData.logoStatus && trustpilotData.logoStatus == 'off' ? 'none' : 'block');
        cssRoot.style.setProperty('--trustpilot-name-display', trustpilotData.companyNameStatus && trustpilotData.companyNameStatus == 'off' ? 'none' : 'block');
        cssRoot.style.setProperty('--trustpilot-add-review-display', trustpilotData.addReviewStatus && trustpilotData.addReviewStatus == 'off' ? 'none' : 'block');
	    cssRoot.style.setProperty('--trustpilot-review-count-display', trustpilotData.reviewCountStatus && trustpilotData.reviewCountStatus == 'off' ? 'none' : 'block');
        cssRoot.style.setProperty('--trustpilot-color', trustpilotData.color || '#000000');
        cssRoot.style.setProperty('--trustpilot-bgcolor', trustpilotData.bgcolor || '#ffffff');
        cssRoot.style.setProperty('--trustpilot-column-count', trustpilotData.layout === 'masonry' ? '3' : 'auto');
        cssRoot.style.setProperty('--trustpilot-sm-column-count', trustpilotData.layout === 'masonry' ? '2' : 'auto');
        cssRoot.style.setProperty('--trustpilot-xs-column-count', trustpilotData.layout === 'masonry' ? '1' : 'auto');

        // Filter reviews
        var totalReviews = 0;
        const reviews = document.querySelectorAll('.brtpmj_single_rvw');
        reviews.forEach(review => {
            const rating = parseInt(review.getAttribute('data-rating'));
            if (reviewFilter === 'all' || rating >= reviewFilter) {
                review.classList.remove('brtpmj-hidden');
                review.classList.add('filtered');

                totalReviews++;
            } else {
                review.classList.add('brtpmj-hidden');
                review.classList.remove('filtered');
            }
        });

        // Load carousel css & script if layout is carousel, remove otherwise
        var carouselStyle = document.getElementById('mjwi-style-carousel'); 
        var carouselScript = document.getElementById('mjwi-script-carousel');
        
        var element = document.querySelector('.carousel-nav');
        if (element) {
            element.remove();
        }
        
        if(trustpilotData.layout === 'carousel') {

            const crsl_btn_html = `
            <div class="carousel-nav">
				<button class="mjwi-slider-btn mjwi-slider-prev" onclick="prevSlide()">&#10094;</button>
				<button class="mjwi-slider-btn mjwi-slider-next" onclick="nextSlide()">&#10095;</button>
            </div>`;
            
            const wrapper = document.getElementsByClassName('carousel-container');
            if (wrapper.length > 0 && totalReviews > 0) {
                wrapper[0].insertAdjacentHTML('beforeend', crsl_btn_html);
            }

            if(!carouselStyle) {
                carouselStyle = document.createElement('link');
                carouselStyle.href = window.mjFrontend + '/styles/carousel.css';
                carouselStyle.id = 'mjwi-style-carousel';
                carouselStyle.rel = "stylesheet";
                carouselStyle.type = "text/css";
                document.head.appendChild(carouselStyle);
            }

            carouselScript = document.createElement('script');
            carouselScript.src = window.mjFrontend + '/scripts/carousel.js';
            carouselScript.type = 'text/javascript';
            carouselScript.id = 'mjwi-script-carousel';
            document.head.appendChild(carouselScript);
            
        } else {
            if (carouselStyle) {
                carouselStyle.parentNode.removeChild(carouselStyle);
            }
            
            if (carouselScript) {
                carouselScript.parentNode.removeChild(carouselScript);
            }

            // restore wrapper position(changed by carousel)
            const wrapper = document.querySelector('.brtpmj_all_reviews');
            if(wrapper) {
                wrapper.style.transform = `translateX(0%)`;//
            }
        }

    }
    
}

export default trustPilotPreview;