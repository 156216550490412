import React, { Component } from 'react';
import getFromUrl from '../Functions/getFromUrl';

import widgetPreview from './widgetPreview.js';

class PreviewWrapper extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            widget: {
                name: '',
                title: '',
                htmlData: {}
            },
            previewHtml: '',
            fieldErrors: '',
        };

    }

    async componentDidMount() {
        
        const widgetFromUrl = JSON.parse( getFromUrl('widget') );
        this.setState({ widget: widgetFromUrl });
        
        widgetPreview(widgetFromUrl);

    }

    render() {

        const { widget, previewHtml } = this.state;

        const widgetName = "mj-" + widget.name;

        return (

            <div className="mj-widget mode-preview" data-type={widget.name} data-widget={JSON.stringify( widget )} id={widgetName} data-server={process.env.REACT_APP_SERVER_URL}>
                <div className={widgetName}>Loading Preview... </div>
            </div>

        );

    }

}

export default PreviewWrapper;