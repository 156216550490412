import React, { Component } from 'react';
import apiInstance from './apiInstance';

import WidgetWrapper from './Editor/WidgetWrapper';

import { connect } from 'react-redux';

import getFromUrl from './Functions/getFromUrl';

class WidgetEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            widget: {
                id: '',
                widgetType: '',
                htmlData: {},
            },
            error: '',
            msg: '',
            msg_display_status: 'none'
        };
    }

    handleErrorMsgUpdate = (error = '', msg = '') => {
        this.setState((prevState) => {
            return ({
                ...prevState,
                error: error,
                msg: msg,
                msg_display_status: ''
            })
        })

        setTimeout(() => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    msg_display_status: 'none'
                };
            });
        }, 5000);
    }

    componentDidMount() {
        const widgetID = getFromUrl('widgetId');
        
        // Get data for existing widget
        if (widgetID) {
            apiInstance.get(`/widget/` + widgetID)
                .then((response) => {
                    if (response.data.error) {
                        this.handleErrorMsgUpdate('Data Error!');
                    }
                    else {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                widget: response.data.widget,
                            })
                        })
                    }
                })
                .catch((error) => {
                    this.handleErrorMsgUpdate('Widget Not Found!');
                    console.error('Error ', error);
                });
        }

    }


    handleSaveChanges = (widget) => {

        const { id } = this.state.widget;
        const { title, widgetType, htmlData } = widget;

        var request_body = { widget: { title: title, widgetType: widgetType, htmlData: htmlData } };

        if (id) {

            apiInstance.put(`/widget/` + id, request_body)
                .then((response) => {
                    if (response.data.error) {

                    } else {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                widget: response.data.widget,
                            })
                        })

                        this.handleErrorMsgUpdate("", "Widget updated Successfully!")

                    }
                })
                .catch((error) => {
                    // console.log(error)
                    if (error.response) {
                        if (error.response.status == 400 && error.response.data.fieldErrors) {

                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    fieldErrors: error.response.data.fieldErrors
                                })
                            })
                        }
                        else {

                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    error: error.response.data
                                })
                            })
                        }
                    } else {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                error: error
                            })
                        })
                    }
                });

        }
        else {

            apiInstance.post(`/widget/`, request_body)
                .then((response) => {
                    if (response.data.error) {

                    } else {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                widget: response.data.widget,
                            })
                        }, async () => {
                            const widgetId = this.state.widget.id;
                            const newUrl = '/editor?widgetId=' + widgetId;
                            window.location.replace(newUrl);
                        });
                        this.handleErrorMsgUpdate("", "Widget added Successfully!")
                    }
                })
                .catch((error) => {
                    // console.log(error)
                    if (error.response) {
                        if (error.response.status == 400 && error.response.data.fieldErrors) {

                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    fieldErrors: error.response.data.fieldErrors
                                })
                            })
                        }
                        else {

                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    error: error.response.data
                                })
                            })
                        }
                    } else {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                error: error
                            })
                        })
                    }
                });
        }
    }

    render() {

        const { widgetTypes } = this.props;
        let { widget } = this.state;
        
        // Prepare widget (available widget data) if not an existing widget
        let { widgetType } = widget;
        if (!widgetType) {
            const typeId = getFromUrl('widgetType');
            for (const wid of widgetTypes) {
                if (wid.id === typeId) {
                    widget = {widgetType: wid, htmlData: {}}
                    break; 
                }
            }
        }

        return (
            <div className="mjwi-editor-wrapper">
                <div style={{ display: this.state.msg_display_status }} className='mjwi-msg-wrapper'>
                    <div className={'mjwi-error-main' + (this.state.error ? '' : ' mjwi-hidden')}>{this.state.error}</div>
                    <div className={'mjwi-msg-main' + (this.state.msg ? '' : ' mjwi-hidden')}>{this.state.msg}</div>
                </div>
                <div className="mjwi-editor-content">

                        <WidgetWrapper
                            thisWidget = {widget}
                            handleSaveChanges = {this.handleSaveChanges}
                            handleErrorMsgUpdate = {this.handleErrorMsgUpdate}
                        />

                </div>
				
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return (
        {
            widgetTypes: state.widgetTypes,
        }
    )
  }

  export default connect(mapStateToProps,null )(WidgetEditor);
  
